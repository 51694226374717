/* eslint-disable no-use-before-define */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import Loading from "ui/Loading"
import { ZErrorInterface } from "libs/zError"
import { apiRegister } from "../../api/apiAuth"
import FormRegisterZError from "./FormRegisterZError"
import {
    FieldEmail,
    FieldUsername,
    FieldPassword,
    FieldPassword2,
} from "./common/formFields"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function FormRegister() {
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [zError, setZError] = useState<ZErrorInterface | null>(null)

    function validateForm() {
        return (
            username.length >= 3 &&
            password.length >= 6 &&
            password === password2
        )
    }

    const handleSubmitClick = (event: React.SyntheticEvent) => {
        event.preventDefault()
        setIsLoading(true)
        setZError(null)
        setIsSuccess(false)

        apiRegister(username, email, password).then((reponse) => {
            console.log("apiRegister.reponse:", reponse)
            if (reponse.user) {
                setIsSuccess(true)
            } else if (reponse.zError) {
                setZError(reponse.zError)
            }
            setIsLoading(false)
        })
    }

    if (isSuccess) {
        return (
            <h3 className="text-success">
                Verifiez vos mails pour valider votre inscription.
            </h3>
        )
    }

    return (
        <StyledLoginForm onSubmit={handleSubmitClick}>
            <FieldUsername username={username} setUsername={setUsername} />
            <FieldEmail email={email} setEmail={setEmail} />
            <FieldPassword password={password} setPassword={setPassword} />
            <FieldPassword2
                password={password}
                password2={password2}
                setPassword2={setPassword2}
            />

            {zError && <FormRegisterZError zError={zError} />}

            {isLoading && <Loading />}

            {!isLoading && !isLoading && (
                <Button
                    size="lg"
                    type="submit"
                    disabled={!validateForm()}
                    className="mt-2"
                >
                    Inscription
                </Button>
            )}
        </StyledLoginForm>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledLoginForm = styled(Form)``
