// ### FUNCTIONS ################################################################

const forceDev = false // ********* A modifier pour forcer le mode dev (en prod, dev=false)
const forceUseLocal = true // **** A modifier pour forcer l'utilisation de la BDD local (en useLocal, dev=false)

// ### FUNCTIONS ################################################################

export const tmdbToken = "30e97c6fb44ac31b005863c5e8895214"

export function getUserToken() {
    return localStorage.getItem("userToken")
}

// ### FUNCTIONS ################################################################

export const dev = process.env.NODE_ENV === "production" ? false : forceDev

export const useLocal =
    process.env.NODE_ENV === "production" ? false : forceUseLocal

export const API_BASE_URL = useLocal
    ? "http://watchlist.api.local/apiz"
    : "https://watchlist.api.zedixi.com/apiz"

// ### FUNCTIONS ################################################################
