// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"
import styled from "styled-components"
import { useAppSelector, useAppDispatch } from "store/store"
import { setMesSeriesDisplay } from "store/seriesSlice"
import { Dropdown } from "react-bootstrap"

// ### TYPES ####################################################################

// ### COMPONENT ################################################################

export default function MenuDisplay() {
    const dispatch = useAppDispatch()
    const { mesSeriesDisplay } = useAppSelector((state) => state.series)
    return (
        <StyledDropdown className="ms-auto">
            <Dropdown.Toggle id="dropdown-basic-menuDisplay">
                Afiichage: {mesSeriesDisplay === "FULL" ? "Full" : "Mini"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item
                    active={mesSeriesDisplay === "VIGNETTES"}
                    onClick={() => dispatch(setMesSeriesDisplay("VIGNETTES"))}
                >
                    Mini
                </Dropdown.Item>
                <Dropdown.Item
                    active={mesSeriesDisplay === "FULL"}
                    onClick={() => dispatch(setMesSeriesDisplay("FULL"))}
                >
                    Full
                </Dropdown.Item>
            </Dropdown.Menu>
        </StyledDropdown>
    )
}

// ### FUNCTIONS ################################################################

// ### STYLES ###################################################################

const StyledDropdown = styled(Dropdown)`
    // background-color: cyan;

    padding: 5px;
    z-index: 300;
    /*
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    */
    // flex-basis: 150px;

    #dropdown-basic-menuDisplay {
        //  background-color: yellow;
        //  z-index: 300;
    }

    button {
        // padding: 10px;
        // width: 100%;
        //  z-index: 300;
        font-size: 0.8em;
    }
`
