// ### IMPORTS ##################################################################

import React from "react"
import { NavLink } from "react-router-dom"
import { Serie } from "types"
import ZTable from "libs/zTable/ZTable"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

function UrlLink(url: string | null, title: string) {
    if (!url) {
        return "---"
    }
    return (
        <a href={url} target="_blank" rel="noreferrer">
            {title}
        </a>
    )
}

function TmdbImg(tmdbImgPath: string | null) {
    if (!tmdbImgPath) {
        return "---"
    }
    return (
        <img
            style={{ height: "60px" }}
            src={"https://image.tmdb.org/t/p/w200/" + tmdbImgPath}
            alt="img"
        />
    )
}

function LastOrNextEpisode(
    seasonNumber: number | null,
    episodeNumber: number | null,
    date: string | null
) {
    return (
        <>
            {seasonNumber && episodeNumber && (
                <>
                    {seasonNumber}x{episodeNumber}
                </>
            )}{" "}
            {date && <>({date})</>}
        </>
    )
}

function NavLinkSerieShow(serieId: number, serieName: string) {
    return <NavLink to={"/series/" + serieId}>{serieName}</NavLink>
}
function SerieUsers({ serieUsers, id }: Serie) {
    return (
        <ul>
            {serieUsers?.map((su) => (
                <li key={"su-" + id + "-" + su.id}>{su.user.username}</li>
            ))}
        </ul>
    )
}
function SerieUsersSort({ serieUsers }: Serie) {
    let str = ""

    serieUsers?.forEach((su) => {
        str += "-" + su.user.username
    })
    return str
}

// ### COMPONENT ################################################################

export default function SeriesTable({ series }: { series: Serie[] }) {
    //  const filterdSeries = series.filter((ser) => ser.statusCouple === "watch")
    // const filterdSeries = series.filter(() => true)
    // console.log("filterdSeries")
    // console.log(filterdSeries)
    const tableColumns = [
        {
            name: "id",
            text: "id",
        },
        {
            name: "tmdbPosterPath",
            text: "tmdb Poster Path",
            cellHtml: (serie: any) => TmdbImg(serie.tmdbPosterPath),
        },
        {
            name: "name",
            text: "name",
            cellHtml: (serie: any) => NavLinkSerieShow(serie.id, serie.name),
        },
        {
            name: "serieUsers",
            text: "serie Users",
            cellHtml: (serie: any) => SerieUsers(serie),
            sortValue: (serie: Serie) => SerieUsersSort(serie),
        },
        {
            name: "tmdbBackdropPath",
            text: "tmdb Backdrop Path",
            cellHtml: (serie: any) => TmdbImg(serie.tmdbBackdropPath),
        },
        {
            name: "tmdbLastEpisode",
            text: "tmdb Last Episode",
            cellHtml: (serie: Serie) =>
                LastOrNextEpisode(
                    serie.tmdbLastEpisodeSeasonNumber,
                    serie.tmdbLastEpisodeNumber,
                    serie.tmdbLastEpisodeDate
                ),
            sortValue: (serie: Serie) =>
                serie.tmdbLastEpisodeSeasonNumber +
                "x" +
                serie.tmdbLastEpisodeNumber +
                "-" +
                serie.tmdbLastEpisodeDate,
        },
        {
            name: "tmdbNextEpisode",
            text: "tmdb Next Episode",
            cellHtml: (serie: Serie) =>
                LastOrNextEpisode(
                    serie.tmdbNextEpisodeSeasonNumber,
                    serie.tmdbNextEpisodeNumber,
                    serie.tmdbNextEpisodeDate
                ),
        },
        {
            name: "tmdbId",
            text: "tmdb Id",
        },
        {
            name: "tmdbStatus",
            text: "tmdb Status",
        },
        {
            name: "tmdbNumberOfSeasons",
            text: "tmdb Number Of Seasons",
        },
        {
            name: "tvmazeUrl",
            text: "tvmaze Url",
            cellHtml: (serie: any) => UrlLink(serie.tvmazeUrl, "tvmaze"),
        },
        {
            name: "epguidesUrl",
            text: "epguides Url",
            cellHtml: (serie: any) => UrlLink(serie.epguidesUrl, "epguides"),
        },
        {
            name: "imdbId",
            text: "imdb Id",
        },
        {
            name: "tmdbLastUpdateAt",
            text: "tmdb Last Update At",
        },
        {
            name: "tmdbLastCheckAt",
            text: "tmdb Last Check At",
        },
    ]

    return (
        <div className="row col-12 p-1 m-4">
            <ZTable
                columns={tableColumns}
                data={series}
                className="table table-bordered table-sm table-striped table-hover bg-light"
            />
        </div>
    )
}
