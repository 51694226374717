// ### IMPORTS ##################################################################

import React from "react"
import styled from "styled-components"
import { ZErrorInterface } from "libs/zError"

// ### COMPONENT ################################################################

export default function FormLoginZError({
    zError,
}: {
    zError: ZErrorInterface
}) {
    if (zError.detail === "UNKNOWN_USERNAME") {
        return <StyledError>Nom d'utilisateur inconnu.</StyledError>
    }
    if (zError.detail === "WRONG_PASSWORD") {
        return <StyledError>Mot de passe incorrect.</StyledError>
    }
    if (zError.detail === "WRONG_USERNAME_OR_PASSWORD") {
        return (
            <StyledError>
                Nom d'utilisateur ou mot de passe incorrect.
            </StyledError>
        )
    }
    if (zError.detail === "EMAIL_NOT_VERIFIED") {
        return (
            <StyledError>
                Vous n'avez pas validé votre inscription. Vérifiez vos mails.
            </StyledError>
        )
    }

    return <StyledError>{zError.detail}</StyledError>
}

// ### STYLES ###################################################################

const StyledError = styled.div`
    color: red;
    margin-top: 10px;
`
