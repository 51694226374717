/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/**
 * Affiche une saison
 * @param   {Object} saison                  -
 * @param   {Object} lastSeenEpisode         - Le dernier épisode vu
 * @param   {number} lastSeenEpisode.saison  - le numéro de la saison
 * @param   {number} lastSeenEpisode.episode - le numéro de l'épisode
 * @param   {number} serieTmdbId             - L'identifiant de la serie sur tmdb
 * @return  {jsx}                            -
 */
// ██████████████████████████████████████████████████████████████████████████████
import React, { useState } from "react"
import { theme } from "styles/theme"
import styled from "styled-components"
import EpisodeItem from "./EpisodeItem"
// ██████████████████████████████████████████████████████████████████████████████

export default function SaisonItem({
    saison,
    // lastSeenEpisode,
    serieUser,
    tmdbSerieId,
}) {
    if (!saison) {
        return <h1>!saison</h1>
    }

    // console.log("----------saison", saison)
    const episodesCount = saison.episodes.length
    const episodes = saison.episodes.sort((a, b) =>
        a.episode_number > b.episode_number
            ? -1
            : b.episode_number > a.episode_number
            ? 1
            : 0
    )
    let saisonVueStr = "" //  "TOUT", "RIEN", ""

    // console.log(saison)
    /*
    if (lastSeenEpisode) {
        if (saison.season_number < lastSeenEpisode.saison) {
            saisonVueStr = "TOUT"
        } else if (saison.season_number > lastSeenEpisode.saison) {
            saisonVueStr = "RIEN"
        } else {
            const maxEpisodeNumber = episodes.reduce(
                (acc, ep) =>
                    (acc = acc > ep.episode_number ? acc : ep.episode_number),
                0
            )
            if (maxEpisodeNumber === lastSeenEpisode.episode) {
                saisonVueStr = "TOUT"
            }
        }
    }
*/
    if (
        serieUser &&
        serieUser.lastSeenEpisodeNumber &&
        serieUser.lastSeenSeasonNumber
    ) {
        if (saison.season_number < serieUser.lastSeenSeasonNumber) {
            saisonVueStr = "TOUT"
        } else if (saison.season_number > serieUser.lastSeenSeasonNumber) {
            saisonVueStr = "RIEN"
        } else {
            const maxEpisodeNumber = episodes.reduce(
                (acc, ep) =>
                    (acc = acc > ep.episode_number ? acc : ep.episode_number),
                0
            )
            if (maxEpisodeNumber === serieUser.lastSeenEpisodeNumber) {
                saisonVueStr = "TOUT"
            }
        }
    }

    const [saisonIsOpen, setSaisonIsOpen] = useState(saisonVueStr !== "TOUT")

    // ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒	RENDER
    return (
        <StyledSaisonItem className="col-12">
            <header onClick={() => setSaisonIsOpen(!saisonIsOpen)}>
                <b>Saison {saison.season_number}</b>
                {saison.name &&
                    saison.name !== "Saison " + saison.season_number && (
                        <span className="saisonName"> : "{saison.name}"</span>
                    )}
                <span className="episodesCount float-end">
                    {episodesCount} épisode{episodesCount > 1 && "s"}
                    {saisonVueStr === "TOUT" &&
                        " vu" + (episodesCount > 1 && "s")}
                    {saisonVueStr === "RIEN" && " à voir"}
                </span>
            </header>
            {saisonIsOpen && (
                <div className="col-12 episodes">
                    {episodes &&
                        episodes.map((episode) => (
                            <EpisodeItem
                                key={"episode" + episode.id}
                                episode={episode}
                                // lastSeenEpisode={lastSeenEpisode}
                                serieUser={serieUser}
                                tmdbSerieId={tmdbSerieId}
                            />
                        ))}
                </div>
            )}
        </StyledSaisonItem>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledSaisonItem = styled.div`
    margin: 5px;
    /*background-color: yellow;*/
    border: 1px solid ${theme.colors.c1};

    header {
        border-bottom: 1px solid ${theme.colors.c1};
        padding: 5px;
        cursor: pointer;
        .saisonName {
            font-style: italic;
        }
        .episodesCount {
            font-size: 0.8em;
        }
    }
    .episodes {
        padding: 5px;
    }
`
