/* eslint-disable no-nested-ternary */
// ### IMPORTS ##################################################################

import React from "react"
import { useAppSelector } from "store/store"
import { SerieUser, SerieUserCustomStatus } from "types"
import SerieUserItemMini from "./SerieUserItemMini"
import SerieUserItemFull from "./SerieUserItemFull"
import HeaderText from "./mesSeriesMenus/HeaderText"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

function sortSerieUsers(
    serieUsers: SerieUser[] | null | undefined,
    sortBy: "NAME" | "NEXT_EPISODE_DATE"
) {
    if (sortBy === "NAME") {
        return serieUsers
            ?.slice()
            .sort((a, b) =>
                a.serie.name > b.serie.name
                    ? 1
                    : b.serie.name > a.serie.name
                    ? -1
                    : 0
            )
    }
    if (sortBy === "NEXT_EPISODE_DATE") {
        return serieUsers
            ?.slice()
            .sort((a, b) =>
                (a.serie.tmdbNextEpisodeDate
                    ? a.serie.tmdbNextEpisodeDate
                    : "999999999") >
                (b.serie.tmdbNextEpisodeDate
                    ? b.serie.tmdbNextEpisodeDate
                    : "999999999")
                    ? 1
                    : (b.serie.tmdbNextEpisodeDate
                          ? b.serie.tmdbNextEpisodeDate
                          : "999999999") >
                      (a.serie.tmdbNextEpisodeDate
                          ? a.serie.tmdbNextEpisodeDate
                          : "999999999")
                    ? -1
                    : 0
            )
    }

    return serieUsers

        ?.slice()
        .sort((a, b) =>
            a.serie.name > b.serie.name
                ? 1
                : b.serie.name > a.serie.name
                ? -1
                : 0
        )
}

// ### COMPONENT ################################################################

export default function SerieUsersListe() {
    const { mesSeriesDisplay, serieUsers, mesSeriesFilterCustomStatus } =
        useAppSelector((state) => state.series)

    const filterdSerieUsers = serieUsers?.filter(
        (su) => su.customStatus === mesSeriesFilterCustomStatus
    )

    /*
     *  Si on affiche les series avec des episodes à venir,
     *  On trie par ""date du prochain episode""
     *  Sinon, on trie ""par nom de serie""
     */
    const sortedAndFilterdSerieUsers = sortSerieUsers(
        filterdSerieUsers,
        mesSeriesFilterCustomStatus === SerieUserCustomStatus.UPCOMMING
            ? "NEXT_EPISODE_DATE"
            : "NAME"
    )

    return (
        <div className="row col-12 p-2">
            <HeaderText count={sortedAndFilterdSerieUsers?.length || 0} />

            {mesSeriesDisplay === "VIGNETTES" &&
                sortedAndFilterdSerieUsers?.map((serieUser: SerieUser) => (
                    <SerieUserItemMini
                        serieUser={serieUser}
                        key={"serie-" + serieUser.id}
                    />
                ))}

            {mesSeriesDisplay === "FULL" &&
                sortedAndFilterdSerieUsers?.map((serieUser: SerieUser) => (
                    <SerieUserItemFull
                        serieUser={serieUser}
                        key={"serie-" + serieUser.id}
                    />
                ))}
        </div>
    )
}
