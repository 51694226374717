/* eslint-disable no-nested-ternary */
// ### IMPORTS ##################################################################

import React from "react"
import { useAppSelector } from "store/store"
import { SerieUserCustomStatus } from "types"
import MenuDisplay from "./MenuDisplay"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function HeaderText({ count }: { count: number }) {
    const { mesSeriesFilterCustomStatus } = useAppSelector(
        (state) => state.series
    )

    return (
        <div className="text-primary d-flex">
            <h5>
                {count} séries{" "}
                {mesSeriesFilterCustomStatus ===
                    SerieUserCustomStatus.UNSEEN && (
                    <> avec des épisodes à voir:</>
                )}
                {mesSeriesFilterCustomStatus ===
                    SerieUserCustomStatus.UPCOMMING && (
                    <> avec des épisodes diffusés prochainement:</>
                )}
                {mesSeriesFilterCustomStatus ===
                    SerieUserCustomStatus.OTHER && (
                    <>
                        {" "}
                        sans épisode programmé <small>(saison terminée)</small>:
                    </>
                )}
                {mesSeriesFilterCustomStatus ===
                    SerieUserCustomStatus.PENDING && (
                    <> que je regarderai peu-être plus tard:</>
                )}
                {mesSeriesFilterCustomStatus ===
                    SerieUserCustomStatus.ENDED && (
                    <> définitivement terminées:</>
                )}
                {mesSeriesFilterCustomStatus ===
                    SerieUserCustomStatus.ABANDONED && (
                    <> que je ne regarde plus:</>
                )}
                {mesSeriesFilterCustomStatus === SerieUserCustomStatus.NO && (
                    <> que je ne regarde pas:</>
                )}
            </h5>
            <MenuDisplay />
        </div>
    )
}
