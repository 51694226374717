// ### IMPORTS ##################################################################

import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import store from "store/store"

import App from "./App"

// ### COMPONENT ################################################################

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <Provider store={store}>
        {/*  <React.StrictMode> */}
        <App />
        {/*    </React.StrictMode> */}
    </Provider>
)

// ### DOCUMENTATION ############################################################

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### STYLES ###################################################################
