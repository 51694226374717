// ### IMPORTS ##################################################################

import React from "react"
import { NavLink } from "react-router-dom"
import { useAppSelector } from "store/store"
import { SerieUser, SerieUserCustomStatus } from "types"
import { theme } from "styles/theme"

import styled from "styled-components"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function SerieUserItem({ serieUser }: { serieUser: SerieUser }) {
    // console.log(serie)

    const { serie } = serieUser
    const { mesSeriesFilterCustomStatus } = useAppSelector(
        (state) => state.series
    )

    console.log(serie)

    return (
        <StyledSerieUserContainer className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <div className="row col-12 contentx">
                <NavLink
                    to={"/series/" + serie.id}
                    className="col-4 img-navlink"
                >
                    <img
                        style={{ width: "100%" }}
                        src={
                            "https://image.tmdb.org/t/p/w200/" +
                            serie.tmdbPosterPath
                        }
                        alt="tmdbPosterPath"
                    />
                </NavLink>
                <div className="row col-8 rightSide">
                    <div className="col-12 name text-primary">{serie.name}</div>
                    {mesSeriesFilterCustomStatus ===
                        SerieUserCustomStatus.UNSEEN && (
                        <div className="col-12 last-episode ">
                            vu:{" "}
                            <b>
                                {serieUser.lastSeenEpisodeNumber ? (
                                    <>
                                        {" "}
                                        {serieUser.lastSeenSeasonNumber}x
                                        {serieUser.lastSeenEpisodeNumber}
                                    </>
                                ) : (
                                    <>Aucun</>
                                )}
                            </b>
                            <br />
                            <br />
                            Dérnier diffusé:
                            <br />
                            <b>
                                {serieUser.serie.tmdbNextEpisodeDate?.split(
                                    "T"
                                )[0] ===
                                new Date().toISOString().split("T")[0] ? (
                                    <>
                                        {" "}
                                        {serie.tmdbNextEpisodeSeasonNumber}x
                                        {serie.tmdbNextEpisodeNumber}
                                    </>
                                ) : (
                                    <>
                                        {serie.tmdbLastEpisodeSeasonNumber}x
                                        {serie.tmdbLastEpisodeNumber} (
                                        {
                                            serie.tmdbLastEpisodeDate?.split(
                                                "T"
                                            )[0]
                                        }
                                        )
                                    </>
                                )}
                            </b>
                            <br />
                        </div>
                    )}
                    {mesSeriesFilterCustomStatus ===
                        SerieUserCustomStatus.UPCOMMING && (
                        <div className="col-12 next-episode-date ">
                            Prochain épisode: <br />
                            <b>
                                {serie.tmdbNextEpisodeSeasonNumber}x
                                {serie.tmdbNextEpisodeNumber}
                            </b>
                            <br />
                            {serie.tmdbNextEpisodeDate?.split("T")[0]}
                        </div>
                    )}
                </div>
            </div>
        </StyledSerieUserContainer>
    )
}

// ### STYLES ###################################################################

const StyledSerieUserContainer = styled.div`
    // background-color: red;
    padding: 5px;
    .contentx {
        border: 1px solid ${theme.colors.c1};
        // background-color: grey;
        // padding: 2px;
        text-align: center;

        .img-navlink {
            // background-color: blue;
            img {
                width: 100%;
                padding: 3px;
            }
            &:hover {
                // background-color: lime;
                img {
                    padding-left: 0px;
                    padding-right: 6px;
                    padding-top: 0px;
                    padding-bottom: 6px;
                }
            }
        }

        .name {
            font-weight: bold;
        }
    }

    a {
        text-decoration: none;
    }

    .next-episode-date {
        font-size: 0.8em;
        color: black;
    }
`
