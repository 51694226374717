/* eslint-disable react/prop-types */ // !!

// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"
import styled from "styled-components"
import { humanDateDifference } from "utils/helpers"
import { IcoAngleUp, IcoAngleDown } from "libs/zIcones"
import ButtonEpisodeVu from "./ButtonEpisodeVu"
import EpisodeDetails from "./EpisodeDetails"

//  ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function EpisodeItem({
    episode,
    // lastSeenEpisode,
    serieUser,
    tmdbSerieId,
}) {
    const dateStrToday = new Date().toISOString().split("T")[0]
    const episodeDiffused = episode.air_date <= dateStrToday

    const [showDetails, setShowDetails] = useState(false)

    let episodeVu = false

    if (
        serieUser &&
        serieUser.lastSeenSeasonNumber &&
        serieUser.lastSeenEpisodeNumber
    ) {
        if (episode.season_number < serieUser.lastSeenSeasonNumber) {
            episodeVu = true
        } else if (
            episode.season_number === serieUser.lastSeenSeasonNumber &&
            episode.episode_number <= serieUser.lastSeenEpisodeNumber
        ) {
            episodeVu = true
        }
    }

    const humanDateDifferenceX = humanDateDifference(
        dateStrToday,
        episode.air_date
    )

    return (
        <StyledEpisodeItem
            className={
                "col-12 " +
                (episodeVu ? " episodeVu " : "") +
                (episodeDiffused ? " episodeDiffused " : " episodeNotDiffused ")
            }
        >
            <div className="episode-row">
                <div className="epNumbers">
                    {episode.season_number}x{episode.episode_number < 10 && "0"}
                    {episode.episode_number}
                </div>
                <div className="epVu">
                    {/*            serieUser &&  episodeDiffused             */}
                    {episodeDiffused ? (
                        <ButtonEpisodeVu
                            //  serieId={serieId}
                            serieUser={serieUser}
                            seasonNumber={episode.season_number}
                            episodeNumber={episode.episode_number}
                            tmdbSerieId={tmdbSerieId}
                            vu={episodeVu}
                        />
                    ) : (
                        "------"
                    )}
                </div>

                <div className="epDate" title={episode.air_date}>
                    {humanDateDifferenceX}
                    {/* ---{episode.air_date} */}
                </div>
                <div
                    role="button"
                    className="epName text-primary"
                    onClick={() => setShowDetails(!showDetails)}
                    onKeyDown={() => setShowDetails(!showDetails)}
                    tabIndex="0"
                >
                    {episode.name}
                    {showDetails ? <IcoAngleUp /> : <IcoAngleDown />}
                </div>
            </div>
            {showDetails && <EpisodeDetails tmdbEpisode={episode} />}
        </StyledEpisodeItem>
    )
}

// ### STYLES ###################################################################

const StyledEpisodeItem = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    flex-basis: 100%;
    //  background-color: yellow;

    .episode-row {
        display: flex;
        flex-basis: 100%;
        .epNumbers {
            // color: blue;
            // DEBUG:           background-color: pink;
            padding-right: 10px;
            white-space: nowrap;
        }
        .epDate {
            // color: blue;
            // background-color: lime;
            padding-left: 10px;
            padding-right: 10px;
            white-space: nowrap;
        }
        .epName {
            // color: blue;
            // DEBUG:        background-color: pink;
            width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            break-after: always;
        }
        .epVu {
            // color: blue;
            // DEBUG:        background-color: green;
            white-space: nowrap;
        }

        // color: red;
        &.episodeVu {
            // color: green !important;
        }

        &:hover {
            background-color: lightgray;
        }
        /*
    &.episodeDiffused {
        color: red !important;
    }
    */
        &.episodeNotDiffused {
            color: red;
        }

        &.episodeDiffused {
        }
    }
`
