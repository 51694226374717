// ### IMPORTS ##################################################################

import { Serie, TmdbSerie, SerieUser, SerieUserCustomStatus } from "types"

// ### FUNCTIONS ################################################################

/**
 *  * renvoie le customStatus "SerieUserCustomStatus" en fonctions des épisodes
 */
export function getSerieUserCutom(su: SerieUser) {
    let customStatus
    if (su.status === "non") {
        customStatus = SerieUserCustomStatus.NO
    } else if (su.status === "abandon") {
        customStatus = SerieUserCustomStatus.ABANDONED
    } else if (su.status === "attente") {
        customStatus = SerieUserCustomStatus.PENDING
    } else if (
        su.serie.tmdbLastEpisodeSeasonNumber &&
        su.serie.tmdbLastEpisodeNumber &&
        (!su.lastSeenSeasonNumber ||
            !su.lastSeenEpisodeNumber ||
            su.lastSeenSeasonNumber < su.serie.tmdbLastEpisodeSeasonNumber ||
            (su.lastSeenSeasonNumber === su.serie.tmdbLastEpisodeSeasonNumber &&
                su.lastSeenEpisodeNumber < su.serie.tmdbLastEpisodeNumber))
    ) {
        customStatus = SerieUserCustomStatus.UNSEEN
    } else if (
        su.serie.tmdbNextEpisodeDate?.split("T")[0] ===
        new Date().toISOString().split("T")[0]
    ) {
        customStatus = SerieUserCustomStatus.UNSEEN
    } else if (su.serie.tmdbNextEpisodeNumber) {
        customStatus = SerieUserCustomStatus.UPCOMMING
    } else if (
        su.serie.tmdbStatus === "Ended" ||
        su.serie.tmdbStatus === "Canceled"
    ) {
        customStatus = SerieUserCustomStatus.ENDED
    } else {
        customStatus = SerieUserCustomStatus.OTHER
    }
    return customStatus
}

/**
 *
 * * Compare une Serie avecsa TmdbSerie pour voir s'il faut la mettre à jour mettre à jour
 * @param {Serie} serie
 * @param {TmdbSerie} tmdbSerie
 * @returns {boolean}
 *
 */
export function needToBeUpdated(serie: Serie, tmdbSerie: TmdbSerie) {
    const serieLastSeason = serie.tmdbLastEpisodeSeasonNumber
    const serieLastEpisode = serie.tmdbLastEpisodeNumber
    const serieLastDate =
        serie.tmdbLastEpisodeDate?.replace(" 00:00:00", "") || null

    const serieNextSeason = serie.tmdbNextEpisodeSeasonNumber
    const serieNextEpisode = serie.tmdbNextEpisodeNumber
    const serieNextDate =
        serie.tmdbNextEpisodeDate?.replace(" 00:00:00", "") || null

    const tmdbLastSeason = tmdbSerie.last_episode_to_air?.season_number || null
    const tmdbLastEpisode =
        tmdbSerie.last_episode_to_air?.episode_number || null
    const tmdbLastDate = tmdbSerie.last_episode_to_air?.air_date || null

    const tmdbNextSeason = tmdbSerie.next_episode_to_air?.season_number || null
    const tmdbNextEpisode =
        tmdbSerie.next_episode_to_air?.episode_number || null
    const tmdbNextDate = tmdbSerie.next_episode_to_air?.air_date || null

    if (
        tmdbLastEpisode !== serieLastEpisode ||
        tmdbLastSeason !== serieLastSeason ||
        tmdbLastDate !== serieLastDate
    ) {
        console.log(
            `Last (serie): ${serieLastSeason}x${serieLastEpisode} (${serieLastDate})`
        )
        console.log(
            `Last (tmdb.): ${tmdbLastSeason}x${tmdbLastEpisode} (${tmdbLastDate})`
        )
        return true
    }
    console.log("LastEpisode OK")

    if (
        tmdbNextEpisode !== serieNextEpisode ||
        tmdbNextSeason !== serieNextSeason ||
        tmdbNextDate !== serieNextDate
    ) {
        console.log(
            `Next (serie): ${serieNextSeason}x${serieNextEpisode} (${serieNextDate})`
        )
        console.log(
            `Next (tmdb.): ${tmdbNextSeason}x${tmdbNextEpisode} (${tmdbNextDate})`
        )
        return true
    }
    console.log("NextEpisode OK")

    return false
}

/**
 *
 * * Compare une Serie avecsa TmdbSerie pour voir s'il faut la mettre à jour mettre à jour
 * @param {Serie} serie
 * @param {TmdbSerie} tmdbSerie
 * @returns {boolean}
 *
 */
export function humanDateDifference(dateStrToday: string, dateStr: string) {
    //  console.log(dateStrToday + " +++ " + dateStr)
    const date1 = new Date(dateStrToday)
    const date2 = new Date(dateStr)

    const diffDays = dateDiff(date1, date2).day

    if (diffDays === 0) {
        return "Aujourd'hui"
    }
    if (diffDays === -1) {
        return "Hier"
    }
    if (diffDays === -2) {
        return "Avant-hier"
    }
    if (diffDays === 1) {
        return "Demain"
    }
    if (diffDays === 2) {
        return "Après-demain"
    }
    if (diffDays > 0 && diffDays < 15) {
        return "Dans " + diffDays + " jours"
    }
    if (diffDays < 0 && diffDays > -15) {
        return "Il y a " + -diffDays + " jours"
    }

    return dateStr
}

export function dateDiff(date1: Date, date2: Date) {
    const diff = { sec: 0, min: 0, hour: 0, day: 0 } // Initialisation du retour
    let tmp = date2.getTime() - date1.getTime()

    tmp = Math.floor(tmp / 1000) // Nombre de secondes entre les 2 dates
    diff.sec = tmp % 60 // Extraction du nombre de secondes

    tmp = Math.floor((tmp - diff.sec) / 60) // Nombre de minutes (partie entière)
    diff.min = tmp % 60 // Extraction du nombre de minutes

    tmp = Math.floor((tmp - diff.min) / 60) // Nombre d'heures (entières)
    diff.hour = tmp % 24 // Extraction du nombre d'heures

    tmp = Math.floor((tmp - diff.hour) / 24) // Nombre de jours restants
    diff.day = tmp

    return diff
}
