// ### IMPORTS ##################################################################

import React from "react"
import { SerieUser } from "types"
import { apiSetSerieUserStatus, apiSetSerieDelete } from "api/apis"
import styled from "styled-components"
import Button from "react-bootstrap/Button"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function SerieUserStatus({
    // serie,
    serieUser,
}: {
    // serie: Serie
    serieUser: SerieUser | null
}) {
    // const variant = "primary"

    // `btn-light btn-outline-${variant}`}
    return (
        <StyledSerieUserContainer className="col-12">
            <div>
                <ButtonStatus
                    text="Je regarde"
                    value="watch"
                    serieUser={serieUser}
                    // serie={serie}
                />
                <ButtonStatus
                    text="En attente"
                    value="attente"
                    serieUser={serieUser}
                    //  serie={serie}
                />
                <ButtonStatus
                    text="Abandonnée"
                    value="abandon"
                    serieUser={serieUser}
                    //  serie={serie}
                />
                <ButtonStatus
                    text="Non"
                    value="non"
                    serieUser={serieUser}
                    //  serie={serie}
                />
            </div>
        </StyledSerieUserContainer>
    )
}

function ButtonStatus({
    text,
    value,
    serieUser,
}: //  serie,
{
    text: string
    value: string
    serieUser: SerieUser | null
    // serie: Serie
}) {
    function handleOnClick() {
        if (serieUser) {
            if (value === "non") {
                handleDeleteSerieUser()
            } else {
                apiSetSerieUserStatus(serieUser.id, value).then(
                    (result) => {
                        console.log("result:::::::", result)

                        if (result.serieUser) {
                            window.location.reload()
                            // dispatch({ type: "CREATE_NOTE", payload: result.note })
                        }

                        // setVuIsLoading(false)
                    },
                    (error) => {
                        //  setVuIsLoading(false)
                        //  TODO
                        console.log("error")
                        console.log(error)
                    }
                )
            }
        }
    }

    function handleDeleteSerieUser() {
        if (serieUser) {
            if (
                serieUser.lastSeenEpisodeNumber !== null &&
                serieUser.lastSeenEpisodeNumber !== 0
            ) {
                if (
                    !window.confirm(
                        'Vous avez commencé cette série. Si vous ne la regardez plus, cliquez plutôt sur "Abandonner".\n\n Voulez vous quand même retirer cette série de votre watchList ?'
                    )
                ) {
                    return
                }
            } else if (
                !window.confirm("Retirer cette série de votre watchList ?")
            ) {
                return
            }

            apiSetSerieDelete(serieUser.id).then(
                (result) => {
                    console.log(result)
                    window.location.reload()
                },
                (error) => {
                    //  setVuIsLoading(false)
                    //  TODO
                    console.log("error")
                    console.log(error)
                }
            )
        }
    }

    return (
        <Button
            onClick={() => handleOnClick()}
            className={
                "m-1 btn-sm" +
                (serieUser?.status === value
                    ? " btn-primary "
                    : " btn-light btn-outline-primary ")
            }
        >
            {text}
        </Button>
    )
}

// ### STYLES ###################################################################

const StyledSerieUserContainer = styled.div`
    // background-color: red;
    padding: 5px; ;
`
