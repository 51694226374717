// ### IMPORTS ##################################################################

import { zApiFetch } from "libs/zFetch"
import { tmdbToken } from "utils/constants"

// ### FUNCTIONS ################################################################

// **** ADMIN

export async function apiFetchSeries() {
    return zApiFetch("/series", "GET", ["series"], null)
}

// **** Fetch: Mes Séries / Serie Show

export async function apiFetchMesSeries() {
    return zApiFetch("/series/mes-series", "GET", ["serieUsers"], null)
}

export async function apiFetchSerie(serieId: number) {
    return zApiFetch("/series/" + serieId, "GET", ["serie"], null)

    // ["serie", "serieUser"]   ::  "serieUser" can be null
}

// **** Update WLSerie

export function apiSetSerieUserStatus(serieUserId: number, status: string) {
    return zApiFetch(
        "/series/serie-user/" + serieUserId + "/set-status/" + status,
        "GET",
        ["serieUser"],
        null
    )
}
export function apiSetSerieDelete(serieUserId: number) {
    return zApiFetch(
        "/series/serie-user/" + serieUserId + "/delete",
        "DELETE",
        ["success"],
        null
    )
}

export function apiSetSerieTmdbCheckAt(serieId: number) {
    return zApiFetch(
        "/series/" + serieId + "/set-tmdb-check-at",
        "GET",
        ["serie"],
        null
    )
}
export async function apiSerieUpdateFromApis(serieId: number) {
    return zApiFetch(
        "/series/" + serieId + "/update-from-apis",
        "GET",
        ["serie"],
        null
    )
}

export function apiSetTmdbIdToSerie(serieId: number, tmdbId: number) {
    return zApiFetch(`/series/` + serieId + "/set-tmdb-id", "POST", ["serie"], {
        tmdbId,
    })
}

// **** Episodes

export function apiSetEpisodeVu(
    serieUserId: number,
    seasonNumber: number,
    episodeNumber: number
) {
    return zApiFetch(
        "/series/serie-user/" +
            serieUserId +
            "/set-episode-vu/" +
            seasonNumber +
            "/" +
            episodeNumber,
        "GET",
        ["serie"],
        null
    )
}

export function apiAddSerieToMyListAndSetEpisodeVu(
    tmdbId: number,
    seasonNumber: number,
    episodeNumber: number
) {
    return zApiFetch(
        "/series/serie-user/create-from-tmdb/" +
            tmdbId +
            "/set-episode-vu/" +
            seasonNumber +
            "/" +
            episodeNumber,
        "GET",
        ["serieUser"],
        null
    )
}

// **** TMDB

export function tmdbApiGetSerieById(
    tmdbId: string,
    inFrench = false,
    withSeasons = false
) {
    let url =
        "https://api.themoviedb.org/3/tv/" + tmdbId + "?api_key=" + tmdbToken

    if (inFrench) {
        url += "&language=fr"
    }
    if (withSeasons) {
        url +=
            "&append_to_response=season/1,season/2,season/3,season/4,season/5,season/6,season/7,season/8,season/9,season/10,season/11,season/12,season/13,season/14,season/15,season/16,season/17,season/18,season/0"
    }

    console.log("➤➤ url:", url)

    return fetch(url)
        .then((response) => response.json())
        .catch((error) => console.error(error))
}
/*
export function apiTmdbFetchEpisode(
    tmdbId: string,
    seasonNumber: number,
    episodeNumber: number,
    inFrench = false
) {
    let url = `/tv/${tmdbId}/season/${seasonNumber}/episode/${episodeNumber}?api_key=${tmdbToken}`

    if (inFrench) {
        url += "&language=fr"
    }

    console.log("➤➤ url:", url)

    return fetch(url)
        .then((response) => response.json())
        .catch((error) => console.error(error))
}
*/
export function apiTmdbTvSearch(text: string) {
    const url =
        "https://api.themoviedb.org/3/search/tv?api_key=" +
        tmdbToken +
        "&language=fr&query=" +
        text
    return fetch(url)
        .then((response) => response.json())
        .catch((error) => console.error(error))
}
export function apiTmdbTvDiscover(pageNumber: number) {
    const url =
        "https://api.themoviedb.org/3/discover/tv?api_key=" +
        tmdbToken +
        "&language=fr-FR" +
        "&page=" +
        pageNumber
    return fetch(url)
        .then((response) => response.json())
        .catch((error) => console.error(error))
}

// ****

export function apiAddTmdbSerie(tmdbSerieId: number) {
    return zApiFetch(
        `/series/create-from-tmdb-id/` + tmdbSerieId,
        "GET",
        ["serieUser"],
        null
    )
}

// ### FUNCTIONS ################################################################
