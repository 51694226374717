// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Serie, SerieUser, TmdbSerie } from "types"
import SerieShow from "features/_serieShow/SerieShow"
import {
    apiFetchSerie,
    tmdbApiGetSerieById,
    apiSerieUpdateFromApis,
    apiSetSerieTmdbCheckAt,
} from "api/apis"
import { ZPage } from "libs/zPage"
import { needToBeUpdated } from "utils/helpers"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageSerieShow() {
    const serieId = Number(useParams().id) || 0
    if (serieId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const [serie, setSerie] = useState<Serie | null>(null)
    const [serieUser, setSerieUser] = useState<SerieUser | null>(null)
    const [tmdbSerie, setTmdbSerie] = useState<TmdbSerie | null>(null)

    function fetchSuccessFunction(result: any) {
        setSerie(result.serie)
        setSerieUser(result.serieUser)

        if (result.serie.tmdbId) {
            tmdbApiGetSerieById(result.serie.tmdbId, true, true).then(
                (resultTmdb) => {
                    console.log("tmdbApiGetSerieById.result", resultTmdb)
                    setTmdbSerie(resultTmdb)

                    if (needToBeUpdated(result.serie, resultTmdb)) {
                        // if (window.confirm("Mettre la BDD à jour ?")) {
                        apiSerieUpdateFromApis(result.serie.id)
                            .then((response) => {
                                if (response.serie) {
                                    window.location.reload()
                                }
                            })
                            .catch((error) => console.error(error))
                        //  }
                    } else {
                        console.log("apiSetSerieTmdbCheckAt")
                        apiSetSerieTmdbCheckAt(result.serie.id)
                    }
                },
                (error) => {
                    console.log("tmdbApiGetSerieById--error:", error)
                }
            )
        }
    }

    return (
        <ZPage
            documentTitle={serie?.name || "Serie #" + serieId}
            fetchFunction={() => apiFetchSerie(serieId)}
            fetchSuccessFunction={(result: any) => fetchSuccessFunction(result)}
        >
            {serie && (
                <SerieShow
                    serie={serie}
                    serieUser={serieUser}
                    tmdbSerie={tmdbSerie}
                />
            )}
        </ZPage>
    )
}

// ### STYLES ###################################################################
