/* eslint-disable no-unused-vars */
export type { default as Serie } from "./serie.type"
// export type { default as TmdbSerieSearchResult } from "./tmdbSerieSearchResult.type"
export type { default as TmdbSeriesSearchResult } from "./tmdbSeriesSearchResult.type"
export type { default as TmdbSerie } from "./tmdbSerie.type"
export type { default as SerieUser } from "./serieUser.type"
export type { default as TmdbEpisode } from "./tmdbEpisode.type"
// export { SerieUserCustomStatus } from "./serieUser.type"

// eslint-disable-next-line no-shadow
export enum SerieUserCustomStatus {
    NO, // !! inutil // * série que je ne regarde pas
    ABANDONED, // * série que j'ai commencé, puis abandonée
    PENDING, // * en attente (ex: à trier, à regarder plus tard)
    ENDED, // * série definitivement terminée ou annulée, Tout vu
    // , CANCELED // * série annulée, Tout vu
    OTHER, // * tout vu, pas d'épisodes à venir (ex: attente prochaine saison)
    UPCOMMING, // * avec un ou plusieurs épisodes à venir
    UNSEEN, // * avec un ou plusieurs épisodes à voir
}
/*
export const enum SerieUserCustomStatus {
    "NO", // !! inutil // * série que je ne regarde pas
    "ABANDONED", // * série que j'ai commencé, puis abandonée
    "PENDING", // * en attente (ex: à trier, à regarder plus tard)
    "ENDED", // * série definitivement terminée ou annulée, Tout vu
    // , "CANCELED" // * série annulée, Tout vu
    "OTHER", // * tout vu, pas d'épisodes à venir (ex: attente prochaine saison)
    "UPCOMMING", // * avec un ou plusieurs épisodes à venir
    "UNSEEN", // * avec un ou plusieurs épisodes à voir
}
*/
