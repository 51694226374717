/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled, { CSSProperties } from "styled-components"
// import { theme } from "styles/theme"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    children?: React.ReactNode | null
    className?: String | null
    style?: CSSProperties
}

export default function ZPageContent({
    children = null,
    className = "",
    style = {},
}: Props) {
    return (
        <StyledZPageContent className={className + " col-12 "} style={style}>
            {children}
        </StyledZPageContent>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledZPageContent = styled.div`
    // display: flex;
    // flex: 1;
    //    background-color: $ {theme.colors.c1l4};
    // background-color: yellow;
    // padding: 5px;
    // padding-bottom: 50px;

    // justify-content: center;    // pour centrer verticalement (loader)
    // min-height: 100%;
`
