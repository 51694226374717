// ### IMPORTS ##################################################################

import React from "react"
import { NavLink } from "react-router-dom"
import { useAppSelector } from "store/store"
import { SerieUser, SerieUserCustomStatus } from "types"

import styled from "styled-components"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function SerieUserItemMini({
    serieUser,
}: {
    serieUser: SerieUser
}) {
    // console.log(serie)

    const { serie } = serieUser
    const { mesSeriesFilterCustomStatus } = useAppSelector(
        (state) => state.series
    )

    return (
        <StyledSerieUserContainer className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
            <div className="col-12 contentx">
                <NavLink to={"/series/" + serie.id} className="col-12">
                    <img
                        src={
                            "https://image.tmdb.org/t/p/w200/" +
                            serie.tmdbPosterPath
                        }
                        alt="tmdbPosterPath"
                    />
                    <div className="col-12">{serie.name}</div>
                    {mesSeriesFilterCustomStatus ===
                        SerieUserCustomStatus.UPCOMMING && (
                        <div className="col-12 next-episode-date">
                            ({serie.tmdbNextEpisodeDate?.slice(0, 10)})
                        </div>
                    )}
                </NavLink>
            </div>
        </StyledSerieUserContainer>
    )
}

// ### STYLES ###################################################################

const StyledSerieUserContainer = styled.div`
    // background-color: red;
    padding: 5px;
    .contentx {
        // border: 1px solid black;
        // background-color: grey;
        // padding: 2px;
        text-align: center;
        img {
            width: 100%;
            padding: 3px;
        }
        &:hover {
            // background-color: lime;
            img {
                padding-left: 0px;
                padding-right: 6px;
                padding-top: 0px;
                padding-bottom: 6px;
            }
        }
    }

    a {
        text-decoration: none;
    }

    .next-episode-date {
        font-size: 0.8em;
        color: black;
    }
`
