// ### IMPORTS ##################################################################
import React from "react"
// import Button from "react-bootstrap/Button"
// import styled from 'styled-components'
import { Serie, TmdbSerie } from "types"
import ButtonLien from "./ButtonLien"

// ### TYPES ####################################################################

type Props = {
    serie: Serie | null
    tmdbSerie: TmdbSerie | null
}

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function SerieLiens({ serie, tmdbSerie }: Props) {
    if (!serie && !tmdbSerie) {
        return null
    }
    return (
        <div className="col-12">
            <div className="col-12">
                {(tmdbSerie || serie?.tmdbId) && (
                    <ButtonLien
                        url={
                            "https://www.themoviedb.org/tv/" +
                            (tmdbSerie?.id || serie?.tmdbId || "") +
                            "?language=fr"
                        }
                        text="TMDB"
                    />
                )}
                {serie?.imdbId && (
                    <ButtonLien
                        url={
                            "https://www.imdb.com/title/" +
                            serie.imdbId +
                            "?language=fr"
                        }
                        text="IMDb"
                    />
                )}
                {serie?.epguidesUrl && (
                    <ButtonLien url={serie.epguidesUrl} text="epGuides" />
                )}
                {serie?.tvmazeUrl && (
                    <ButtonLien url={serie.tvmazeUrl} text="tvMaze" />
                )}
            </div>
            {/* 
            {serie.liens && (
                <div className="col-12">
                    {serie.liens.map((lien) => {
                        return (
                            <span key={"slien-" + lien.id}>
                                <ButtonLien
                                    url={lien.url}
                                    text={lien.url}
                                    variant={
                                        lien.valide ? "secondary" : "success"
                                    }
                                />
                                <Button
                                    variant="success"
                                    onClick={() => setSelectedLien(lien)}
                                >
                                    edit
                                </Button>
                            </span>
                        )
                    })}
                </div>
            )}
            */}
        </div>
    )
}

// ### STYLES ###################################################################
