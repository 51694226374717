// ### IMPORTS ##################################################################

import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import styled from "styled-components"
import { useAppSelector } from "store/store"
import { theme } from "styles/theme"
import { IcoSearch } from "libs/zIcones"
import { useLocal } from "utils/constants"
import DropdownConnectedUser from "./DropdownConnectedUser"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

NavMenuLink.defaultProps = {
    className: "",
    title: "",
}

function NavMenuLink({
    to,
    children,
    className,
    title,
}: {
    to: string
    children: React.ReactNode
    className?: string
    title?: string
}) {
    return (
        <LinkContainer to={to} className={className} title={title}>
            <Nav.Link>{children}</Nav.Link>
        </LinkContainer>
    )
}

export default function NavMenu() {
    const { authUsername } = useAppSelector((state) => state.auth)

    // const userIsWebmaster = false
    const isLocalServer =
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1" ||
        window.location.hostname === ""

    return (
        <StyledNavbar
            className="bg-primary bg-gradient fixed-top"
            // user_is_admin={userIsWebmaster ? 1 : 0}
            // is_local_server={isLocalServer ? 1 : 0}
        >
            <Nav className="col-12 me-auto">
                <NavMenuLink to="/" title="WachList" className="text-center">
                    <>
                        <img
                            src="/img/app-logo/app-logo-32.png"
                            alt="wl"
                            className="wl-logo"
                        />
                        <div className="app-title me-2 text-light">
                            watchList
                        </div>
                    </>
                </NavMenuLink>
                <div id="NavMenuLinks">
                    <NavMenuLink to="/">Mes Séries</NavMenuLink>
                    {/* 
                    <NavMenuLink to="/series">Séries</NavMenuLink>
                    */}
                    <NavMenuLink to="/series/search">
                        <IcoSearch /> Rechercher
                    </NavMenuLink>
                    {!authUsername && (
                        <NavMenuLink to="/auth/login">Login</NavMenuLink>
                    )}
                </div>
                {useLocal && (
                    <div className="badge bg-info">
                        LOCAL
                        <br />
                        BDD
                    </div>
                )}
                {isLocalServer && (
                    <div className="badge bg-success ">
                        LOCAL
                        <br />
                        HOST
                    </div>
                )}
                <DropdownConnectedUser />
            </Nav>
        </StyledNavbar>
    )
}

// ### STYLES ###################################################################

const StyledNavbar = styled(Navbar)`
    // background-color: ${theme.colors.c1};

    height: 70px;
    width: 100% !important;
    display: flex;
    align-items: center;
    z-index: 400px;
    .badge {
        background-color: orange;
        align-self: center;
    }

    .tmdb-logo {
        height: 50px;
        align-self: center;
    }
    .app-title {
        font-size: 0.8em;
    }

    #NavMenuLinks {
        // background-color: red;
        padding: 5px;
        display: flex;
        flex-grow: 1;
        justify-content: space-around;
        align-items: center;

        a {
            color: ${theme.colors.c1l4};
            &.active {
                color: white !important;
            }
        }
    }
    /*

    .container-fluid {
        width: 100% !important;
        margin-left: 0px;
        padding: 0px;
    }
    .container {
        width: 100% !important;
        padding: 0px;
        min-width: 100% !important;
        margin-left: 0px;
    }

    .nav-link {
        font-size: 0.9em !important;
    }

    #user-basic-nav-dropdown {
        margin-left: auto !important;
    }
*/
`
