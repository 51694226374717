// ### DOCUMENTATION ############################################################

/*
 *
 *
 *
 */

// ### IMPORTS ##################################################################

import React, { useState, useEffect } from "react"
// import Button from "react-bootstrap/Button"
import { ZModal } from "libs/ZModal"

import styled from "styled-components"
import {
    //  apiSetTmdbIdToSerie,
    //     apiAddTmdbSerie,
    tmdbApiGetSerieById,
} from "api/apis"
import { TmdbSerie } from "types"
import { theme } from "styles/theme"
// import { IcoPlus, IcoEye } from "libs/zIcones"
import SeasonsAndEpisodes from "../episodes/SeasonsAndEpisodes"
import ButtonEpisodeVu from "../episodes/ButtonEpisodeVu"
// import Loading from '../_nav/Loading'
// export const FormContext = React.createContext({	form: {}	});

// ### TYPES ####################################################################

type Props = {
    tmdbSerie: TmdbSerie
    handleClose: () => void
}

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function AddTmdbSerieToWatchlist({
    tmdbSerie,
    handleClose,
}: Props) {
    //  console.log("----mesSeriesUsers")
    //   console.log(mesSeriesUsers)

    // const [isAdding, setIsAdding] = useState(false)
    const [tmdbSerieFull, setTmdbSerieFull] = useState<TmdbSerie | null>(null)

    useEffect(() => {
        tmdbApiGetSerieById(tmdbSerie.id.toString(), true, true).then(
            (resultTmdb) => {
                console.log("tmdbApiGetSerieById.result", resultTmdb)
                setTmdbSerieFull(resultTmdb)
            },
            (error) => {
                console.log("tmdbApiGetSerieById--error:", error)
            }
        )
    }, [])

    console.log("___AddTmdbSerieToWatchlist___")
    console.log("tmdbSerie", tmdbSerie)

    return (
        <ZModal
            headerText="Ajoutez à votre watchList"
            isShown
            hide={() => handleClose()}
        >
            <StyledXxxxxxxxxxxxxxx>
                <h3>{tmdbSerie.name}</h3>
                {/* 
                En cours (série que vous suivez actuellement ou que vous allez
                bientôt regarder)
                <br />
                En attente (à voir plus tard)
                <br />
                Abandonné (vous avez commencé mais vous ne regardez plus)
                <br />
                <br />
                */}
                <strong className="text-primary">
                    Indiquez le dernier épisode vu:
                </strong>
                <br />
                <ButtonEpisodeVu
                    seasonNumber={0}
                    episodeNumber={0}
                    serieUser={null}
                    vu={false}
                    tmdbSerieId={tmdbSerie.id}
                />
                {tmdbSerieFull ? (
                    <SeasonsAndEpisodes
                        tmdbSerie={tmdbSerieFull}
                        serieUser={null}
                    />
                ) : (
                    <h4>Loading</h4>
                )}
            </StyledXxxxxxxxxxxxxxx>
        </ZModal>
    )
}

// ### STYLES ###################################################################

const StyledXxxxxxxxxxxxxxx = styled.div`
    border: 1px solid ${theme.colors.c1l4};
    width: 100%;
    padding: 5px;
    padding-bottom: 20px;
`
