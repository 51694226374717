// ### IMPORTS ##################################################################

// import React, { useEffect, useState } from "react"
import React from "react"
import { theme } from "styles/theme"

import { Serie, SerieUser, TmdbSerie } from "types"
import styled from "styled-components"
import SerieInfos from "./SerieInfos"
// import SerieInfosXX from "./SerieInfosXX"
// import SerieInfosTmdb from "./SerieInfosTmdb"
// import SerieLiens from "./SerieLiens"
import SerieUserStatus from "features/SerieUserStatus"
// import TmdbSearch from "../tmdbSeries/TmdbSearch"
import SerieSaisonsAndEpisodes from "../episodes/SeasonsAndEpisodes"

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function SerieShow({
    serie,
    serieUser,
    tmdbSerie,
}: {
    serie: Serie | null
    serieUser: SerieUser | null
    tmdbSerie: TmdbSerie | null
}) {
    return (
        <StyledPageSerieShowContent>
            {/* 
            {!serie.tmdbId && (
                <div className="row col-12 pb-5">
                    <TmdbSearch
                        initialSearchedText={serie.name}
                        wlSerieId={serie.id}
                    />
                </div>
            )}
            */}
            <div id="serieHeader">
                <img
                    className="serieHeader-bg"
                    src={
                        "https://image.tmdb.org/t/p/w780/" +
                        (tmdbSerie?.backdrop_path ||
                            serie?.tmdbBackdropPath ||
                            "")
                    }
                    alt=""
                />

                <img
                    src="/img/powered_by_tmdb.png"
                    alt="tmdb"
                    className="tmdb-logo"
                />
                <div className="serieHeader-content">
                    <h1>{tmdbSerie?.name || serie?.name || "???"}</h1>
                    <h3>(x épisodes à voir)</h3>
                    {/* 
                <div>
                    <p>innerWidth: {windowSize.innerWidth}</p>
                    <p>outerWidth: {windowSize.outerWidth}</p>
                    <p>screen.availWidth: {windowSize.screen.availWidth}</p>
                    <p>screen.width: {windowSize.screen.width}</p>
                </div>
                */}
                </div>
            </div>

            <div id="sidesContainer">
                <div id="sideAB">
                    <div id="sideA">
                        <img
                            alt="tmdbPosterPath"
                            src={
                                "https://image.tmdb.org/t/p/w500/" +
                                (tmdbSerie?.poster_path ||
                                    serie?.tmdbPosterPath ||
                                    "")
                            }
                        />

                        <div className="col-12">
                            <SerieUserStatus
                                serieUser={serieUser}
                                // serie={serie}
                            />
                        </div>
                    </div>

                    <div id="sideB">
                        <SerieInfos
                            serie={serie}
                            tmdbSerie={tmdbSerie} /* serieUser={serieUser} */
                        />
                    </div>
                </div>
                <div id="sideC">
                    <SerieSaisonsAndEpisodes
                        tmdbSerie={tmdbSerie}
                        serieUser={serieUser}
                    />
                </div>
            </div>
        </StyledPageSerieShowContent>
    )
}

// ### STYLES ###################################################################

const StyledPageSerieShowContent = styled.div`
    // DEBUG:        background-color: purple;
    // DEBUG:        padding: 10px;
    // background-color: black;
    display: flex;
    flex-direction: column;

    h2,
    h3,
    h4,
    h5,
    b,
    strong {
        color: ${theme.colors.c1};
    }

    #serieHeader {
        // DEBUG:        background-color: orange;
        // DEBUG:        padding: 10px;
        color: white;
        // -webkit-text-stroke: 1px #000;
        text-shadow: 0 0 4px #000;
        height: 200px;
        padding: 20px;

        position: relative;

        .serieHeader-bg {
            opacity: 0.7;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
        .tmdb-logo {
            position: absolute;
            right: 10px;
            top: 130px;
            height: 60px;
        }
        .serieHeader-content {
            position: relative;
            text-align: center;

            h1 {
                font-size: 2.5em;
                font-weight: bold;
            }
            h3 {
                color: white;
            }
        }
    }

    #sidesContainer {
        // DEBUG:        background-color: lime;
        padding: 10px;
        display: flex;
        flex-direction: row;
        // flex-wrap: wrap;

        #sideAB {
            // DEBUG:        background-color: blue;
            // DEBUG:        padding: 10px;
            display: flex;

            #sideA {
                // DEBUG:        background-color: pink;
                // DEBUG:        padding: 10px;
                img {
                    width: 100%;
                }
            }
            #sideB {
                // DEBUG:        background-color: yellow;
                // DEBUG:        padding: 10px;
            }
        }
        #sideC {
            // DEBUG:        background-color: cyan;
            // DEBUG:        padding: 10px;
        }
    }

    @media all and (min-width: 901px) {
        // DEBUG:        background-color: yellow;
        #sideAB {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 600px; // ????
            flex-direction: row;
            #sideA {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: 300px;
                flex-direction: column;
            }
            #sideB {
                flex-grow: 4;
                flex-shrink: 1;
                flex-basis: 600px;
            }
        }
        #sideC {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 400px;
        }
    }

    @media all and (min-width: 701px) and (max-width: 900px) {
        // DEBUG:        background-color: green;
        #sidesContainer {
            #sideAB {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 600px;
                flex-direction: column;
                #sideA {
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: 600px;
                    flex-direction: column;
                }
            }
        }
    }

    @media all and (max-width: 700px) {
        // DEBUG:        background-color: red;

        #serieHeader {
            padding-left: 150px;
            h1 {
                font-size: 2em;
            }
        }
        #sidesContainer {
            flex-direction: column;
            #sideAB {
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: auto;
                flex-direction: column;
                #sideA {
                    img {
                        // border: 3px solid red;
                        position: absolute;
                        top: 100px;
                        left: 20px;
                        width: 100px;
                    }
                }
            }
        }
    }
`
