// *** slice : “a collection of Redux reducer logic and actions for a single feature in your app.”

// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import { createSlice } from "@reduxjs/toolkit"
import { SerieUser, SerieUserCustomStatus } from "types"
import { getSerieUserCutom } from "utils/helpers"

// ### TYPES ####################################################################

// Type for our state
interface SeriesState {
    serieUsers: SerieUser[] | null
    mesSeriesFilterCustomStatus: SerieUserCustomStatus
    mesSeriesDisplay: string // "VIGNETTES" | "FULL"
}

// ### FUNCTIONS ################################################################

// Initial state
const initialState: SeriesState = {
    serieUsers: null,
    mesSeriesFilterCustomStatus: SerieUserCustomStatus.UNSEEN,
    mesSeriesDisplay: "FULL", // "VIGNETTES" | "FULL"
}

// ### COMPONENT ################################################################

// Actual Slice
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setSerieUsers(state, action) {
            const serieUsers = action.payload
            serieUsers?.forEach((su: SerieUser) => {
                su.customStatus = getSerieUserCutom(su)
            })
            state.serieUsers = serieUsers
        },
        setMesSeriesFilterCustomStatus(state, action) {
            state.mesSeriesFilterCustomStatus = action.payload
        },
        setMesSeriesDisplay(state, action) {
            state.mesSeriesDisplay = action.payload
        },
        addSerieUser(state, action) {
            const su = action.payload
            su.customStatus = getSerieUserCutom(su)
            if (state.serieUsers) {
                state.serieUsers = [...state.serieUsers, su]
            }
        },
        resetSeriesSlice(state) {
            // * Quand l'utilisateur se déconnecte
            state.serieUsers = null
            state.mesSeriesFilterCustomStatus = SerieUserCustomStatus.UNSEEN
            state.mesSeriesDisplay = "FULL" // "VIGNETTES" | "FULL"
        },
    },
})

export const {
    setSerieUsers,
    setMesSeriesFilterCustomStatus,
    addSerieUser,
    setMesSeriesDisplay,
    resetSeriesSlice,
} = authSlice.actions

export default authSlice.reducer
