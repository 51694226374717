/* eslint-disable import/prefer-default-export */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import styled from 'styled-components'

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const theme = {
    colors: {
        c1l5: "#f6eaee",
        c1l4: "#edd6de",
        c1l3: "#e4c1cd",
        c1l2: "#ca849c",
        c1l1: "#b95a7a",

        c1: "#a8325a",

        c1d1: "#972d51",
        c1d2: "#ca849c",
        c1d3: "#75233e",
        c1d4: "#641e36",
        c1d5: "#431424",

        danger: "#AA0000",
    },
}
