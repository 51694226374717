/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
// import { theme } from "styles/theme"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    children?: React.ReactNode
    className?: String | null
}

ZPageSectionHelp.defaultProps = {
    children: null,
    className: "",
}

export default function ZPageSectionHelp({ children, className }: Props) {
    return (
        <StyledZPageSectionHelp className={className + ""}>
            <section>{children}</section>
        </StyledZPageSectionHelp>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledZPageSectionHelp = styled.div`
    padding: 10px;
    section {
        background-color: rgb(230, 255, 230);
        border: 1px solid green;
        font-size: 0.8em;
        padding: 10px;
        h2 {
            color: green;
            font-size: 1.2em;
        }
        p {
            //  margin-left: 10px;
        }
    }
`
