// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"

import { ZPage, ZPageHeader, ZPageContent } from "libs/zPage"

import TmdbSearch from "features/tmdbSeries/TmdbSearch"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageSerieSearch() {
    return (
        <ZPage
            documentTitle="Rechercher une série"
            // fetchFunction={() => apiFetchSerie(serieId)}
            // fetchSuccessFunction={(result: any) => setSerie(result.serie)}
        >
            <ZPageHeader>
                <h1>Ajoutez des séries à votre watchList</h1>
            </ZPageHeader>
            <ZPageContent>
                <TmdbSearch />
            </ZPageContent>
        </ZPage>
    )
}

// ### STYLES ###################################################################
