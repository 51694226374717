/* eslint-disable no-nested-ternary */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import Loading from "ui/Loading"
// import { ButtonNavLinkLogin } from "features/auth/common/buttonsNavLink"
import { apiRegisterMailValidation } from "api/apiAuth"
import FormLogin from "features/auth/FormLogin"
import { ZErrorInterface } from "libs/zError"
import RegisterValidationZError from "features/auth/RegisterValidationZError"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// ** Quand un User crée un compte, il recpoit un mail avec un lien vers cette page pour valider son mail
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function PageAuthRegisterValidation() {
    const { email, token } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [zError, setZError] = useState<ZErrorInterface | null>(null)

    useEffect(() => {
        document.title = "cmzEDT: Postes"

        setIsLoading(true)
        setZError(null)
        setIsSuccess(false)

        if (!email) {
            setZError({ detail: "email missing" })
        } else if (!token) {
            setZError({ detail: "token missing" })
        } else {
            apiRegisterMailValidation(email, token).then((result) => {
                if (result.user) {
                    setIsSuccess(true)
                } else if (result.zError) {
                    setZError(result.zError)
                }
                setIsLoading(false)
            })
        }
    }, [])

    return (
        <div className="row col-12 m-1">
            <StyledPageLoginXXXXXXXXXX>
                <h1 className="app-title mb-4">watchlist.zedixi.com</h1>
                <img
                    className="app-logo mb-4"
                    alt="cmzEDT"
                    src="/img/app-logo/app-logo-512.png"
                />
                {!isSuccess && (
                    <h1 className="mb-2">Validation de l&apos;inscription</h1>
                )}
                {isLoading && <Loading />}
                {isSuccess && (
                    <div>
                        Votre inscription a été validée.
                        <br />
                        Vous pouvez désormais vous connecter.
                        <br /> <br />
                        <FormLogin />
                    </div>
                )}
                {zError && <RegisterValidationZError zError={zError} />}
                {/* <ButtonNavLinkLogin /> */}
            </StyledPageLoginXXXXXXXXXX>
        </div>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPageLoginXXXXXXXXXX = styled.div`
    /*color: $C1d1 !important;*/
    text-align: center;
    margin: 0 auto;

    @media all and (min-width: 320px) {
        padding: 60px 0;
        max-width: 320px;
        .app-title {
            font-size: 1.5em;
        }
        .app-logo {
            width: 200px;
        }
    }

    @media all and (max-width: 319px) {
        padding: 10px 0;
        .app-title {
            font-size: 1.1em;
        }
        .app-logo {
            width: 60%;
        }
    }
`
