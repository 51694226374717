/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { theme } from "styles/theme"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    children?: React.ReactNode
    className?: String | null
}

ZPageTitle.defaultProps = {
    children: null,
    className: "",
}

export default function ZPageTitle({ children, className }: Props) {
    return (
        <StyledPageTitle className={className + " col-12"}>
            {children}
        </StyledPageTitle>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledPageTitle = styled.div`
    display: flex;
    flex-grow: 1;
    font-size: 1.5em;
    color: ${theme.colors.c1};
    padding: 5px;
    // font-weight:bold;
    .subtitle {
        padding-left: 40px;
        font-size: 0.5em;
    }
`
