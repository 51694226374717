// ### IMPORTS ##################################################################

import React from "react"
import { TmdbSerie, TmdbSeriesSearchResult, SerieUser } from "types"
import styled from "styled-components"

import TmdbSearchResultItem from "./TmdbSearchResultItem"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function TmdbSearchResult({
    apiResult,
    wlSerieId,
    mesSeriesUsers,
}: //  setMesSerieUsers,
{
    apiResult: TmdbSeriesSearchResult
    wlSerieId: number | null | undefined
    mesSeriesUsers: SerieUser[] | null
    //   setMesSerieUsers: (_aaa: any) => void
}) {
    return (
        <StyledTmdbResultsContainer className="col-12">
            {apiResult.results.map((serie: TmdbSerie) => (
                <TmdbSearchResultItem
                    tmdbSerie={serie}
                    wlSerieId={wlSerieId || null}
                    key={"showowo-" + serie.id}
                    mesSeriesUsers={mesSeriesUsers}
                    // setMesSerieUsers={setMesSerieUsers}
                />
            ))}
        </StyledTmdbResultsContainer>
    )
}

// ### STYLES ###################################################################

const StyledTmdbResultsContainer = styled.div`
    /*
    display: flex;
    flex-wrap: wrap;
*/
    // background-color: cyan;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    //grid-template-columns: 260px 100%;
`
