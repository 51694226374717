// ### IMPORTS ##################################################################

import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useAppSelector } from "store/store"
import "bootstrap/dist/css/bootstrap.min.css"
import "styles/global.scss"

import NavMenu from "ui/navMenu/NavMenu"

import PageAuthLogin from "pages/PageAuthLogin"
import PageAuthRegister from "pages/PageAuthRegister"
import PageAuthRegisterValidation from "pages/PageAuthRegisterValidation"

import PageAdminSeriesIndex from "pages/PageAdminSeriesIndex"
import PageAdminUsersIndex from "pages/PageAdminUsersIndex"

import PageMesSeries from "pages/PageMesSeries"
import PageSerieShow from "pages/PageSerieShow"
import PageSerieTmdbShow from "pages/PageSerieTmdbShow"
import PageSerieSearch from "pages/PageSerieSearch"

// ### COMPONENT ################################################################

export default function App() {
    const { authState } = useAppSelector((state) => state.auth)
    if (!authState) {
        return (
            <Router>
                <Routes>
                    <Route
                        path="/auth/register"
                        element={<PageAuthRegister />}
                    />
                    <Route
                        path="/auth/register/validation/:email/:token"
                        element={<PageAuthRegisterValidation />}
                    />
                    <Route path="/auth/login" element={<PageAuthLogin />} />
                    <Route path="*" element={<PageAuthLogin />} />
                </Routes>
            </Router>
        )
    }
    return (
        <Router>
            <>
                <NavMenu />
                <Routes>
                    <Route
                        path="/admin/series"
                        element={<PageAdminSeriesIndex />}
                    />
                    <Route
                        path="/admin/users"
                        element={<PageAdminUsersIndex />}
                    />
                    <Route
                        path="/series/Search"
                        element={<PageSerieSearch />}
                    />
                    <Route
                        path="/series/tmdb/:id"
                        element={<PageSerieTmdbShow />}
                    />
                    <Route path="*" element={<PageMesSeries />} />
                    <Route path="/series/:id" element={<PageSerieShow />} />
                    <Route path="*" element={<PageMesSeries />} />
                </Routes>
            </>
        </Router>
    )
}

// ### DOCUMENTATION ############################################################

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### STYLES ###################################################################
