// ### IMPORTS ##################################################################

import React from "react"
import styled from "styled-components"
import { ZErrorInterface } from "libs/zError"

// ### COMPONENT ################################################################

export default function RegisterValidationZError({
    zError,
}: {
    zError: ZErrorInterface
}) {
    if (zError.detail === "ERR_EMAIL_NOT_FOUND") {
        return <StyledError>L'adresse email ne correspond pas.</StyledError>
    }
    if (zError.detail === "ERR_INVALID_TOKEN") {
        return <StyledError>Le token ne correspond pas.</StyledError>
    }

    return <StyledError>{zError.detail}</StyledError>
}

// ### STYLES ###################################################################

const StyledError = styled.div`
    color: red;
    margin-top: 10px;
`
