// ### DOCUMENTATION ############################################################

/*
 *
 *
 *
 */

// ### IMPORTS ##################################################################

import React, { useState, useEffect } from "react"
import { useAppSelector, useAppDispatch } from "store/store"
import { setSerieUsers } from "store/seriesSlice"
import { Button } from "react-bootstrap"
import { apiTmdbTvSearch, apiTmdbTvDiscover, apiFetchMesSeries } from "api/apis"
import { TmdbSeriesSearchResult } from "types"
import TmdbSearchResult from "./TmdbSearchResult"

// ### TYPES ####################################################################

type Props = {
    initialSearchedText?: string
    wlSerieId?: number | null
}

TmdbSearch.defaultProps = {
    initialSearchedText: "",
    wlSerieId: null,
}

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function TmdbSearch({
    initialSearchedText = "",
    wlSerieId,
}: Props) {
    const dispatch = useAppDispatch()
    const { serieUsers } = useAppSelector((state) => state.series)
    // const [mesSerieUsers, setMesSerieUsers] = useState<SerieUser[] | null>(null)
    const [searchedText, setSearchedText] = useState(initialSearchedText)
    const [apiResult, setApiResult] = useState<TmdbSeriesSearchResult | null>(
        null
    )
    const [apiResultPopulaire, setApiResultPopulaire] =
        useState<TmdbSeriesSearchResult | null>(null)

    const [appIsFetching, setAppIsFetching] = useState(false)

    const [populairePageNumber, setPopulairePageNumber] = useState(1)

    useEffect(() => {
        if (serieUsers === null) {
            // setFetchState("LOADING")
            apiFetchMesSeries().then((result: any) => {
                if (result.zError) {
                    //     setFetchState("ERROR")
                } else {
                    dispatch(setSerieUsers(result.serieUsers))
                    //  setFetchState("")
                }
            })
        }
    }, [])

    /*
    useEffect(() => {
        setMesSerieUsers(null)
        apiFetchMesSeries().then(
            (result) => {
                //   console.log(result)
                setMesSerieUsers(result.serieUsers)
            },
            (error) => {
                setAppIsFetching(false)
                console.log(error)
                console.log("error")
                console.log("eeeeeeeeeeeeeeeeee")
            }
        )
    }, [])
    */

    useEffect(() => {
        setAppIsFetching(true)

        setApiResultPopulaire(null)
        apiTmdbTvDiscover(populairePageNumber).then(
            (result) => {
                //   console.log(result)
                setAppIsFetching(false)

                setApiResultPopulaire(result)
            },
            (error) => {
                setAppIsFetching(false)
                console.log(error)
                console.log("error")
                console.log("eeeeeeeeeeeeeeeeee")
            }
        )
    }, [populairePageNumber])

    console.log("searchedText", searchedText)
    console.log(searchedText)

    useEffect(() => {
        setAppIsFetching(true)
        if (searchedText === "") {
            setApiResult(null)
        } else {
            setApiResult(null)
            apiTmdbTvSearch(searchedText).then(
                (result) => {
                    //     console.log(result)
                    setAppIsFetching(false)
                    setApiResult(result)
                },
                (error) => {
                    setAppIsFetching(false)
                    console.log(error)
                    console.log("error")
                    console.log("eeeeeeeeeeeeeeeeee")
                }
            )
        }
    }, [searchedText])

    return (
        <div>
            <div className="page-content row col-12 m-5">
                <form className="mt-2 mb-5">
                    <input
                        type="text"
                        className="row col-6"
                        value={searchedText || ""}
                        onChange={(e) => setSearchedText(e.target.value)}
                        placeholder="Chercher une série"
                    />
                </form>

                {searchedText === "" ? (
                    <h2>Séries populaires:</h2>
                ) : (
                    <h2>Résultats de votre recherche:</h2>
                )}

                {appIsFetching && <h1>Loading.........</h1>}

                {searchedText !== "" &&
                    apiResult &&
                    apiResult.total_results &&
                    apiResult.results && (
                        <div className="row col-12">
                            <TmdbSearchResult
                                apiResult={apiResult}
                                wlSerieId={wlSerieId}
                                mesSeriesUsers={serieUsers}
                                //  setMesSerieUsers={setMesSerieUsers}
                            />
                        </div>
                    )}

                {searchedText === "" &&
                    apiResultPopulaire &&
                    apiResultPopulaire.total_results &&
                    apiResultPopulaire.results && (
                        <div className="row col-12">
                            <TmdbSearchResult
                                apiResult={apiResultPopulaire}
                                wlSerieId={wlSerieId}
                                mesSeriesUsers={serieUsers}
                                //  setMesSerieUsers={setMesSerieUsers}
                            />
                            <div className="row col-12 text-center p-5">
                                <Button
                                    onClick={() =>
                                        setPopulairePageNumber(
                                            populairePageNumber - 1
                                        )
                                    }
                                    className="col-5"
                                    disabled={populairePageNumber < 2}
                                >
                                    Précédent
                                </Button>
                                <div className="col-2">
                                    page {populairePageNumber}
                                </div>
                                <Button
                                    onClick={() =>
                                        setPopulairePageNumber(
                                            populairePageNumber + 1
                                        )
                                    }
                                    className="col-5"
                                    disabled={
                                        populairePageNumber >=
                                        apiResultPopulaire.total_pages - 1
                                    }
                                >
                                    Suivant
                                </Button>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )
}
