/* eslint-disable react/jsx-props-no-spreading */

// ** ignoré dans .prettierignore

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"

import {
    FaEye,
    // ++++++++++
    FaUser,
    FaUsers,
    FaUserSlash,
    FaUserCheck,
    FaUserClock,
    FaUserPlus,
    FaUserMinus,
    FaUserEdit,
    FaUserTimes,
    FaUserCog,
    FaUserLock,
    FaUserShield,
    FaPrint,
    FaAngleDown,
    FaAngleUp,

    // FaRegSmileWink,
    FaQuestion,
    FaSearch,
    FaPlus,
    FaEdit,
    FaBan,
    FaTrashAlt,
    FaHome,
    FaKey,
    FaCheck,
    FaTimes,
    FaExclamationTriangle,
    FaCartArrowDown,
    FaLock,
    FaLockOpen,
    FaBell,
    FaEnvelope,
    FaWrench,
    // window size
    FaWindowMaximize,
    FaWindowMinimize,
    FaWindowRestore,
    FaWindowClose,
} from "react-icons/fa"

import { VscSplitVertical, VscSplitHorizontal } from "react-icons/vsc"
import { AiOutlineColumnHeight, AiOutlineColumnWidth } from "react-icons/ai"
import { BiLeftArrow, BiRightArrow } from "react-icons/bi"
import {
    BsClockHistory,
    BsArrowReturnRight,
    BsZoomIn,
    BsZoomOut,
} from "react-icons/bs"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// *************    NEW in CMZEDT - a ajouter sur les autres sites    ********************



export function IcoEye(props: any) {        return <FaEye {...props} />
}
export function IcoMail(props: any) {       return <FaEnvelope {...props} />}
export function IcoOutil(props: any) {      return <FaWrench {...props} />}
export function IcoPrint(props: any) {      return <FaPrint {...props} />}
export function IcoZoomIn(props: any) {     return <BsZoomIn {...props} />}
export function IcoZoomOut(props: any) {    return <BsZoomOut {...props} />}



// *************    window size    ********************

export function IcoWindowMaximize(props: any) { return <FaWindowMaximize {...props} />}
export function IcoWindowMinimize(props: any) { return <FaWindowMinimize {...props} />}
export function IcoWindowRestore(props: any) {  return <FaWindowRestore {...props} />}
export function IcoWindowClose(props: any) {    return <FaWindowClose {...props} />}

// FaKey

// *************    CRUD    ********************

export function IcoPlus(props: any) {    return <FaPlus {...props} />}
export function IcoDelete(props: any) {    return <FaTrashAlt {...props} />}
export function IcoEdit(props: any) {    return <FaEdit {...props} />}

// *************    USER    ********************
export function IcoUserSlash(props: any) {    return <FaUserSlash {...props} />}
export function IcoUsers(props: any) {    return <FaUsers {...props} />}
export function IcoUser(props: any) {    return <FaUser {...props} />}
export function IcoUserPlus(props: any) {    return <FaUserPlus {...props} />}
export function IcoUserMinus(props: any) {    return <FaUserMinus {...props} />}
export function IcoUserTimes(props: any) {    return <FaUserTimes {...props} />}
export function IcoUserEdit(props: any) {    return <FaUserEdit {...props} />}
export function IcoUserCheck(props: any) {    return <FaUserCheck {...props} />}
export function IcoUserClock(props: any) {    return <FaUserClock {...props} />}
export function IcoUserCog(props: any) {    return <FaUserCog {...props} />}
export function IcoUserLock(props: any) {    return <FaUserLock {...props} />}
export function IcoUserShield(props: any) {    return <FaUserShield {...props} />}

// *************    Triangle / Arrow    ********************

export function IcoTriangleLeft(props: any) {    return <BiLeftArrow {...props} />}
export function IcoTriangleRight(props: any) {    return <BiRightArrow {...props} />}
export function IcoArrowDownRight(props: any) {    return <BsArrowReturnRight {...props} />}
export function IcoAngleDown(props: any) {    return <FaAngleDown {...props} />}
export function IcoAngleUp(props: any) {    return <FaAngleUp {...props} />}

// *************    XXXXXX    ********************

export function IcoHome(props: any) {    return <FaHome {...props} />}
export function IcoBan(props: any) {    return <FaBan {...props} />}
export function IcoKey(props: any) {    return <FaKey {...props} />}
export function IcoTrue(props: any) {    return <FaCheck {...props} />}
export function IcoFalse(props: any) {    return <FaTimes {...props} />}
export function IcoQuestion(props: any) {    return <FaQuestion {...props} />}
export function IcoDanger(props: any) {   return <FaExclamationTriangle {...props} />}
export function IcoSearch(props: any) {    return <FaSearch {...props} />}
export function IcoBell(props: any) {    return <FaBell {...props} />}
export function IcoPeriode(props: any) {    return <BsClockHistory {...props} />}

// *************    XXXXXX    ********************

export function IcoSplitVertical(props: any) {    return <VscSplitVertical {...props} />}
export function IcoSplitHorizontal(props: any) {    return <VscSplitHorizontal {...props} />}
export function IcoHeight(props: any) {    return <AiOutlineColumnHeight {...props} />}
export function IcoWidth(props: any) {    return <AiOutlineColumnWidth {...props} />}
export function IcoPanierAdd(props: any) {    return <FaCartArrowDown {...props} />}
export function IcoLocked(props: any) {    return <FaLock {...props} />}
export function IcoUnlocked(props: any) {    return <FaLockOpen {...props} />}

// *************    XXXXXX    ********************

export function IcoAll() {
    return (
        <div className="p-3  d-flex">
            <div className="m-1  col-2">
                <RenderIco name="IcoHome">
                    <IcoHome />
                </RenderIco>
                <RenderIco name="IcoBan">
                    <IcoBan />
                </RenderIco>
                <RenderIco name="IcoKey">
                    <IcoKey />
                </RenderIco>
            </div>
            <div className="m-1  col-2">
                <RenderIco name="IcoPlus">
                    <IcoPlus />
                </RenderIco>
                <RenderIco name="IcoEdit">
                    <IcoEdit />
                </RenderIco>
                <RenderIco name="IcoDelete">
                    <IcoDelete />
                </RenderIco>
            </div>
            <div className="m-1  col-2">
                <RenderIco name="IcoUsers">
                    <IcoUsers />
                </RenderIco>
                <RenderIco name="IcoUser">
                    <IcoUser />
                </RenderIco>
                <RenderIco name="IcoUserAdd">
                    <IcoUserPlus />
                </RenderIco>
            </div>
            <div className="m-1  col-2">
                <RenderIco name="IcoSplitVertical">
                    <IcoSplitVertical />
                </RenderIco>
                <RenderIco name="IcoSplitHorizontal">
                    <IcoSplitHorizontal />
                </RenderIco>
                <RenderIco name="IcoHeight">
                    <IcoHeight />
                </RenderIco>
                <RenderIco name="IcoWidth">
                    <IcoWidth />
                </RenderIco>
            </div>
            <div className="m-1  col-2">
                <RenderIco name="IcoQuestion">
                    <IcoQuestion />
                </RenderIco>
                <RenderIco name="IcoTrue">
                    <IcoTrue />
                </RenderIco>
                <RenderIco name="IcoFalse">
                    <IcoFalse />
                </RenderIco>
                <RenderIco name="IcoDanger">
                    <IcoDanger />
                </RenderIco>
                <RenderIco name="IcoSearch">
                    <IcoSearch />
                </RenderIco>{" "}
                <RenderIco name="IcoPanierAdd">
                    <IcoSearch />
                </RenderIco>
                {/*
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                <RenderIco name="IcoXxxxxxxx"><IcoXxxxxxxx /></RenderIco>
                */}
            </div>
        </div>
    )
}

interface RenderIcoProps {
    name: string
    children: any
}

function RenderIco({ name, children }: RenderIcoProps) {
    return (
        <div className="col-12">
            {name}: {children}
        </div>
    )
}
