/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "store/store"
import { authLogoutSuccess } from "store/authSlice"
import { NavDropdown } from "react-bootstrap"
import { IcoUser } from "libs/zIcones"
// import { theme } from "styles/theme"
// import { IcoUser } from "styles/icones"
import { FaSignOutAlt } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function DropdownConnectedUser() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { authUsername } = useAppSelector((state) => state.auth)
    if (!authUsername) {
        return null
    }

    return (
        <NavDropdown
            style={{ alignSelf: "center" }}
            title={
                <>
                    <IcoUser /> {/*  {authUsername} */}
                </>
            }
            id="user-basic-nav-dropdown"
            className="ms-auto pe-1"
            align="end"
        >
            <NavDropdown.Item disabled>
                <IcoUser /> {authUsername}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => btLogoutClick()}>
                <FaSignOutAlt /> Déconnexion
            </NavDropdown.Item>
            <NavDropdown.Item href="#todo" disabled>
                <FaSignOutAlt /> Déconnecter de tous les appareils
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {authUsername === "P8D" && (
                <>
                    <NavDropdown.Item href="/admin/series">
                        Admin: Séries
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/admin/users">
                        Admin: Users
                    </NavDropdown.Item>
                </>
            )}
        </NavDropdown>
    )

    function btLogoutClick() {
        dispatch(authLogoutSuccess())
        navigate("/")
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
