// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"
import styled from "styled-components"
import { useAppSelector, useAppDispatch } from "store/store"
import { setMesSeriesFilterCustomStatus } from "store/seriesSlice"
import { Button, Dropdown } from "react-bootstrap"
import { SerieUserCustomStatus } from "types"

// ### TYPES ####################################################################

// ### COMPONENT ################################################################

export default function MenuFilters() {
    const dispatch = useAppDispatch()
    const { mesSeriesFilterCustomStatus } = useAppSelector(
        (state) => state.series
    )
    return (
        <StyledMenu>
            <ButtonFilterStatus
                value={SerieUserCustomStatus.UNSEEN}
                text="A voir"
            />
            <ButtonFilterStatus
                value={SerieUserCustomStatus.UPCOMMING}
                text="A venir"
            />
            <ButtonFilterStatus
                value={SerieUserCustomStatus.OTHER}
                text="Rien de prévu"
            />

            <StyledDropdown>
                <Dropdown.Toggle
                    id="dropdown-basic-qdfgqdfgqdfgsdfg"
                    className={
                        " only-mobile " +
                        (mesSeriesFilterCustomStatus !==
                            SerieUserCustomStatus.UNSEEN &&
                        mesSeriesFilterCustomStatus !==
                            SerieUserCustomStatus.UPCOMMING &&
                        mesSeriesFilterCustomStatus !==
                            SerieUserCustomStatus.OTHER
                            ? " btn-primary "
                            : " btn-light btn-outline-primary ")
                    }
                >
                    +
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() =>
                            dispatch(
                                setMesSeriesFilterCustomStatus(
                                    SerieUserCustomStatus.PENDING
                                )
                            )
                        }
                    >
                        En attente
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() =>
                            dispatch(
                                setMesSeriesFilterCustomStatus(
                                    SerieUserCustomStatus.ENDED
                                )
                            )
                        }
                    >
                        Terminées
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() =>
                            dispatch(
                                setMesSeriesFilterCustomStatus(
                                    SerieUserCustomStatus.ABANDONED
                                )
                            )
                        }
                    >
                        Abandonnées
                    </Dropdown.Item>
                    {/* 
                    <Dropdown.Item
                        onClick={() =>
                            dispatch(
                                setMesSeriesFilterCustomStatus(
                                    SerieUserCustomStatus.NO
                                )
                            )
                        }
                    >
                        Non****
                    </Dropdown.Item>
                    */}
                </Dropdown.Menu>
            </StyledDropdown>

            <ButtonFilterStatus
                value={SerieUserCustomStatus.PENDING}
                text="En attente"
                className="no-mobile"
            />
            <ButtonFilterStatus
                value={SerieUserCustomStatus.ENDED}
                text="Terminées"
                className="no-mobile"
            />
            <ButtonFilterStatus
                value={SerieUserCustomStatus.ABANDONED}
                text="Abandonnées"
                className="no-mobile"
            />
            {/* 
            <ButtonFilterStatus
                value={SerieUserCustomStatus.NO}
                text="Non"
                className="no-mobile"
            />
            */}
        </StyledMenu>
    )
}

// ### FUNCTIONS ################################################################

ButtonFilterStatus.defaultProps = { className: "" }

function ButtonFilterStatus({
    value,
    text,
    className = "",
}: {
    value: SerieUserCustomStatus
    text: string
    className?: string
}) {
    const dispatch = useAppDispatch()
    const { mesSeriesFilterCustomStatus } = useAppSelector(
        (state) => state.series
    )

    return (
        <StyledButton
            onClick={() => dispatch(setMesSeriesFilterCustomStatus(value))}
            className={
                className +
                (mesSeriesFilterCustomStatus === value
                    ? " btn-primary "
                    : " btn-light btn-outline-primary ")
            }
        >
            {text}
        </StyledButton>
    )
}

// ### STYLES ###################################################################

const StyledMenu = styled.div`
    background-color: white;
    padding: 5px;
    position: sticky;
    top: 70px; // * la hauteur du navMenu
    width: 100%;
    height: 60px;
    display: flex;
    z-index: 800;
    // flex-wrap: wrap;
    justify-content: space-between;
`

const StyledButton = styled(Button)`
    // background-color: cyan;

    margin: 2px;
    flex-grow: 1;
    flex-shrink: 1;
    // flex-basis: 150px;

    @media all and (max-width: 699px) {
        &.no-mobile {
            display: none;
        }
    }
    @media all and (min-width: 700px) {
        &.only-mobile {
            display: none;
        }
    }
`

const StyledDropdown = styled(Dropdown)`
    // background-color: cyan;

    padding: 5px;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    // flex-basis: 150px;
    button {
        padding: 10px;
        width: 100%;
    }

    .dropdown-menu {
        // color: red;
        // background-color: blue;
        // font-size: 2em;
    }
    @media all and (min-width: 700px) {
        display: none;
    }
`
