/* eslint-disable react/prop-types */ // !!

// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"
import styled from "styled-components"

//  ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function EpisodeDetails({
    tmdbEpisode,
    // lastSeenEpisode,
    //  serieUser,
    tmdbSerieId,
}) {
    // console.log(tmdbEpisode)

    return (
        <StyledEpisodeDetails className="epDetails col-12 ">
            <img
                src={
                    "https://image.tmdb.org/t/p/w200/" + tmdbEpisode.still_path
                }
                alt=""
            />
            <div className="rightSide">
                {/* 
                <div className="detail-name">{tmdbEpisode.name}</div>
                <div className="detail-xxx">
                    <div className="epNumbers">
                        {tmdbEpisode.season_number}x
                        {tmdbEpisode.episode_number < 10 && "0"}
                        {tmdbEpisode.episode_number}
                    </div>

                    <div className="epDate">{tmdbEpisode.air_date}</div>
                    
                </div>
                */}
                <div className="detail-overview">
                    {tmdbEpisode.overview || "Pas de résumé pour cet épisode."}
                </div>

                <a
                    className="lien"
                    href={
                        "https://www.themoviedb.org/tv/" +
                        tmdbSerieId +
                        "/season/" +
                        tmdbEpisode.season_number +
                        "/episode/" +
                        tmdbEpisode.episode_number +
                        "?language=fr"
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    Plus d'infos sur TMDB
                </a>
            </div>
        </StyledEpisodeDetails>
    )
}

// ### STYLES ###################################################################

const StyledEpisodeDetails = styled.div`
    display: flex;
    flex-direction: row;
    //  font-size: 0.8em;
    flex-basis: 100%;
    // background-color: lime;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid red;
    img {
        max-height: 80px;
    }
    .rightSide {
        // background-color: cyan;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-left: 5px;
        .detail-name {
            font-weight: bold;
        }

        .detail-xxx {
            display: flex;
            flex-direction: row;
            div {
                padding-right: 10px;
            }
        }
    }
`
