/* eslint-disable no-unsafe-finally */

// ### IMPORTS ##################################################################

import { API_BASE_URL } from "utils/constants"
import { zApiFetch } from "libs/zFetch"

// ### FUNCTIONS ################################################################

export async function apiLogin(username: string, password: string) {
    return zApiFetch(
        "/authentification/login",
        "POST",
        ["user"],
        { username, password },
        true
    )
}

export async function apiRegister(
    username: string,
    email: string,
    password: string
) {
    return zApiFetch(
        "/authentification/register",
        "POST",
        ["user"],
        { username, email, password },
        true
    )
}

export async function apiRegisterMailValidation(email: string, token: string) {
    return zApiFetch(
        "/authentification/register/validation",
        "POST",
        ["user"],
        { email, token },
        true
    )
}

export async function apiPasswordReset(
    userId: string,
    token: string,
    email: string,
    password: string
) {
    let success = false
    let error = null
    const url = API_BASE_URL + "/authentification/password_reset/" + userId
    console.log("apiPasswordReset - url:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({ token, email, password }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        console.log("AAAAAA.rep", rep)
        if (rep.message && rep.message === "SUCCESS") {
            success = true
        } else if (rep.error) {
            error = rep.error
            /*
            if (rep.error === "ERR_EMAIL_MISMATCH") {
                error = "L'adresse email ne correspond pas."
            } else if (rep.error === "ERR_TOKEN_MISMATCH") {
                error =
                    "Le token ne correspond pas. Si vous avez fait plusieurs demandes de réinitialisation, verifiez que vous avez cliquez sur le dernier mail reçu. Sinon, recommencez."
            } else {
                error = "Erreur inconnue."
            }
            */
        } else {
            console.log("rep--------------------:" + rep)
            error = "Erreur inconnue..."
        }
    } catch (err) {
        error = "CatchError: " + err
    } finally {
        return {
            success,
            error,
        }
    }
}

export async function apiPassWordForgottenSendMail(email: string) {
    let success = false
    let error = null
    const url = API_BASE_URL + "/authentification/password_forgotten/send_mail"
    console.log("apiPassWordForgottenSendMail - url:", url)

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({ email }),
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()
        // console.log("AAAAAA.rep", rep)
        if (rep.message && rep.message === "SUCCESS") {
            success = true
        } else if (rep.error) {
            if (rep.error === "ERR_EMAIL_NOT_FOUND") {
                error = "Cette adresse ne correspond à aucun utilisateur"
            } else {
                error = "Erreur inconnue."
            }
        } else {
            console.log("rep--------------------:" + rep)
            error = "Erreur inconnue..."
        }
    } catch (err) {
        error = "CatchError: " + err
    } finally {
        return {
            success,
            error,
        }
    }
}
