// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import { API_BASE_URL, getUserToken } from "utils/constants" // ?????????????????
import { ZErrorInterface } from "libs/zError"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

export async function zApiFetch(
    shortUrl: string,
    method: "GET" | "POST" | "DELETE",
    elements: string[],
    body: any,
    publicAccess?: boolean
) {
    const url = API_BASE_URL + shortUrl
    console.group("🟨🟨🟨   zApiFetch")
    console.log("➤➤ url:", url)

    const requestOptions = {
        method,
        headers: publicAccess
            ? requestOptionsHeadersPublic()
            : requestOptionsHeaders(),
        body: body ? JSON.stringify(body) : null,
    }

    try {
        const response = await fetch(url, requestOptions)
        const rep = await response.json()

        const missingElements = [] as string[]
        elements.forEach((elem) => {
            if (!rep[elem]) {
                missingElements.push(elem)
            }
        })

        if (missingElements.length === 0) {
            console.log("✔️ SUCCESS")
            console.log("✔️ zApiFetch.rep:", rep)
            console.log(rep)
            console.groupEnd()
            return rep
        }

        console.log("❌ missingElements", missingElements)
        console.groupEnd()

        return { zError: errorManager(rep) }
    } catch (err) {
        console.groupEnd()
        return { zError: errorManager(err) }
    }
}

export function requestOptionsHeaders() {
    return {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getUserToken(),
    }
}
export function requestOptionsHeadersPublic() {
    return {
        "Content-Type": "application/json",
        Accept: "application/json",
    }
}

export function errorManager(reponse: any, requierdFields = []) {
    console.log("requierdFields", requierdFields)
    console.log("errorManager...reponse", reponse)
    let zError: ZErrorInterface

    if (reponse.status && reponse.detail) {
        zError = {
            status: reponse.status,
            detail: reponse.detail,
        }
        console.log("❌ errorManager - withStatusAndDetail: ", zError)
    } else if (reponse.apiError) {
        zError = {
            userMessage: reponse.apiError,
            devMessage: reponse.apiError,
        }
        console.log("❌ errorManager - apiError: ", zError)
    } else if (reponse.error) {
        zError = {
            userMessage: reponse.error,
            devMessage: reponse.error,
        }
        console.log("❌ errorManager - apiError: ", zError)
    } else if (reponse.apixError) {
        zError = {
            userMessage: reponse.apixError,
            devMessage: reponse.apixError,
        }
        console.log("❌ errorManager - apixError: ", zError)
    } else {
        zError = {
            userMessage: "Erreur Inconnu",
        }
    }
    return zError
}
