/* eslint-disable react/function-component-definition */
// ### IMPORTS ##################################################################
import React, { FunctionComponent } from "react"
import ReactDOM from "react-dom"
import {
    Wrapper,
    Header,
    StyledModal,
    HeaderText,
    CloseButton,
    Content,
    Backdrop,
} from "./zModal.style"

// ### COMPONENT ################################################################

export interface ModalProps {
    isShown: boolean
    hide: () => void
    children: React.ReactNode
    headerText: string
}

export const ZModal: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    children,
    headerText,
}) => {
    const modal = (
        <>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    <Header>
                        <HeaderText>{headerText}</HeaderText>
                        <CloseButton onClick={hide}>X</CloseButton>
                    </Header>
                    <Content>{children}</Content>
                </StyledModal>
            </Wrapper>
        </>
    )

    return isShown ? ReactDOM.createPortal(modal, document.body) : null
}
