/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!

// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Serie, SerieUser, TmdbSerie } from "types"
import SerieShow from "features/_serieShow/SerieShow"
import {
    apiFetchSerie,
    tmdbApiGetSerieById,
    apiSerieUpdateFromApis,
    apiSetSerieTmdbCheckAt,
} from "api/apis"
import { ZPage } from "libs/zPage"
import { needToBeUpdated } from "utils/helpers"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageSerieTmdbShow() {
    const tmdbSerieId = Number(useParams().id) || 0
    if (tmdbSerieId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const [serie, setSerie] = useState<Serie | null>(null)
    const [serieUser, setSerieUser] = useState<SerieUser | null>(null)
    const [tmdbSerie, setTmdbSerie] = useState<TmdbSerie | null>(null)
    const [success, setSuccess] = useState(false)

    function fetchSuccessFunction(result: any) {}

    useEffect(() => {
        setSuccess(false)
        tmdbApiGetSerieById(tmdbSerieId.toString(), true, true).then(
            (resultTmdb) => {
                console.log("tmdbApiGetSerieById.result", resultTmdb)
                setTmdbSerie(resultTmdb)
                setSuccess(true)
            },
            (error) => {
                console.log("tmdbApiGetSerieById--error:", error)
            }
        )
    }, [])

    function fetchFunction() {
        tmdbApiGetSerieById(tmdbSerieId.toString(), true, true).then(
            (resultTmdb) => {
                console.log("tmdbApiGetSerieById.result", resultTmdb)
                setTmdbSerie(resultTmdb)
            },
            (error) => {
                console.log("tmdbApiGetSerieById--error:", error)
            }
        )
    }

    return (
        <ZPage
            documentTitle={serie?.name || "tmdb Serie #" + tmdbSerieId}
            // fetchFunction={() => fetchFunction()}
            //  fetchSuccessFunction={(result: any) => fetchSuccessFunction(result)}
        >
            <h1>PageSerieTmdbShow</h1>
            {success && (
                <SerieShow
                    serie={serie}
                    serieUser={serieUser}
                    tmdbSerie={tmdbSerie}
                />
            )}
        </ZPage>
    )
}

// ### STYLES ###################################################################
