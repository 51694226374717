// ### IMPORTS ##################################################################
import styled from "styled-components"
import { theme } from "styles/theme"

// ### STYLES ###################################################################

export const Wrapper = styled.div`
    position: fixed;
    // max-height: 90%; // ne pas mettre la hauteur ici mais dans son content !!!!
    max-width: 90%;

    outline: 0;
    z-index: 1110;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const Backdrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(168, 50, 90, 0.8);
    z-index: 1100; // navMenu à 1030 !
`

export const StyledModal = styled.div`
    z-index: 100;
    background: white;
    position: relative;
    margin: auto;
    border-radius: 8px;
`

export const Header = styled.div`
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
`

export const HeaderText = styled.div`
    align-self: center;
    color: ${theme.colors.c1};
`

export const CloseButton = styled.button`
    font-size: 1rem;
    border: none;
    border-radius: 3px;
    margin-left: 0.5rem;
    background: none;
    :hover {
        cursor: pointer;
    }
`

export const Content = styled.div`
    padding: 10px;
    // max-height: 30rem;
    // height: 50rem;
    max-height: 90vh;

    overflow-x: hidden;
    overflow-y: auto;
`
