// ### DOCUMENTATION ############################################################
/**
 *  * Page: Mes Séries
 *  * On affiche les séries de l'utilisateurs
 *
 */
// ### IMPORTS ##################################################################

import React, { useState, useEffect } from "react"
import { useAppSelector, useAppDispatch } from "store/store"
import { setSerieUsers } from "store/seriesSlice"
import SerieUsersListe from "features/_mesSeries/SerieUsersListe"
import MenuFilters from "features/_mesSeries/mesSeriesMenus/MenuFilters"
import MessageNoSeriesUsers from "features/_mesSeries/MessageNoSeriesUsers"
import { apiFetchMesSeries } from "api/apis"
import { ZPage, ZPageContent } from "libs/zPage"
// import { SerieUserCustomStatus } from "types"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageMesSeries() {
    const dispatch = useAppDispatch()
    const { serieUsers } = useAppSelector((state) => state.series)
    const [fetchState, setFetchState] = useState("")

    useEffect(() => {
        if (serieUsers === null) {
            setFetchState("LOADING")
            apiFetchMesSeries().then((result: any) => {
                if (result.zError) {
                    setFetchState("ERROR")
                } else {
                    dispatch(setSerieUsers(result.serieUsers))
                    setFetchState("")
                }
            })
        }
    }, [])

    console.log("fetchState", fetchState)

    return (
        <ZPage
            documentTitle="Series"
            isLoading={fetchState === "LOADING"}
            error={fetchState === "ERROR" ? "Erreur" : null}
            // fetchFunction={() => apiFetchMesSeries()}
            // fetchSuccessFunction={(result: any) => dispatch(setSerieUsers(result.serieUsers)) }
        >
            <ZPageContent>
                {serieUsers && serieUsers.length === 0 ? (
                    <MessageNoSeriesUsers />
                ) : (
                    <>
                        <MenuFilters />
                        <SerieUsersListe />
                    </>
                )}
            </ZPageContent>
        </ZPage>
    )
}

// ### STYLES ###################################################################
