// ### DOCUMENTATION ############################################################

/*
 *
 *
 *
 */

// ### IMPORTS ##################################################################

import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import { NavLink } from "react-router-dom"

import styled from "styled-components"
import {
    apiSetTmdbIdToSerie,
    //  apiAddTmdbSerie,
    //  tmdbApiGetSerieById,
} from "api/apis"
import { TmdbSerie, SerieUser } from "types"
import { theme } from "styles/theme"
import { IcoPlus, IcoEye } from "libs/zIcones"
import AddTmdbSerieToWatchlist from "./AddTmdbSerieToWatchlist"
// import Loading from '../_nav/Loading'
// export const FormContext = React.createContext({	form: {}	});

// ### TYPES ####################################################################

type Props = {
    tmdbSerie: TmdbSerie
    wlSerieId: number | null
    mesSeriesUsers: SerieUser[] | null
    // setMesSerieUsers: (_aaa: any) => void
}

// ### FUNCTIONS ################################################################

function GetImageFromApi({ serie }: { serie: TmdbSerie }) {
    if (serie.poster_path === null) {
        return null
    }
    //   return "https://image.tmdb.org/t/p/w200/" + name
    return (
        <img
            style={{ width: "100px" }}
            src={"https://image.tmdb.org/t/p/w200/" + serie.poster_path}
            alt="tmdbPosterPath"
        />
    )
}
// ### COMPONENT ################################################################

export default function TmdbSearchResultItem({
    tmdbSerie,
    wlSerieId = null,
    mesSeriesUsers,
}: // setMesSerieUsers,
Props) {
    //  console.log("----mesSeriesUsers")
    //   console.log(mesSeriesUsers)

    const [isAdding, setIsAdding] = useState(false)
    // const [serieUserAdded, setSerieUserAdded] = useState(null)
    const mySerie = mesSeriesUsers?.filter(
        (su) => su.serie.tmdbId?.toString() === tmdbSerie.id.toString()
    )
    // eslint-disable-next-line no-unneeded-ternary
    const inList = mySerie && mySerie?.length > 0 ? true : false

    if (inList) {
        return null
    }

    const buttonAssociateClick = () => {
        //  console.clear()

        apiSetTmdbIdToSerie(wlSerieId || 0, tmdbSerie.id)
            .then((response) => {
                if (response.serie) {
                    window.location.reload()
                }
            })
            .catch((error) => console.error(error))
    }

    const buttonAddSerieClick = () => {
        setIsAdding(true)
    }

    return (
        <StyledResultItem inList={inList} className="col-4 col-lg-2 ">
            {/* 
            <a
                href={"https://www.themoviedb.org/tv/" + tmdbSerie.id}
                target="_blank"
                rel="noreferrer"
            >

                <GetImageFromApi serie={tmdbSerie} />
            </a>
            */}
            <GetImageFromApi serie={tmdbSerie} />

            <NavLink to={"/series/tmdb/" + tmdbSerie.id} className="col-12">
                <div className="name">{tmdbSerie.name}</div>
            </NavLink>

            {!isAdding && inList && (
                <div
                    className="butonInList"
                    // className="btn-sm"
                    // variant="primary"
                >
                    <IcoEye />
                </div>
            )}

            {!isAdding && !inList && wlSerieId && (
                <Button onClick={buttonAssociateClick} className="btn-sm">
                    Associate to #{wlSerieId}
                </Button>
            )}
            {!isAdding && !inList && !wlSerieId && (
                <Button
                    onClick={buttonAddSerieClick}
                    // variant="primary"
                    className="btn-light btn-outline-primary"
                >
                    <IcoPlus />
                </Button>
            )}
            {isAdding && (
                <AddTmdbSerieToWatchlist
                    tmdbSerie={tmdbSerie}
                    handleClose={() => setIsAdding(false)}
                />
            )}
        </StyledResultItem>
    )
}

// ### STYLES ###################################################################

const StyledResultItem = styled.div<{ inList: boolean }>`
    border: 1px solid ${theme.colors.c1l4};
    width: 100%;
    padding: 5px;
    .name {
        // color: ${theme.colors.c1};
        text-align: center;
    }
    //  padding-bottom: 10px;
    /*
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 150px;
    */
    position: relative;
    img {
        width: 100% !important;
    }
    button,
    .butonInList {
        // font-size: 8em;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .butonInList {
        background-color: ${theme.colors.c1};
        border-radius: 5px;
        padding: 0px 10px 5px 10px; // t r b l
        font-size: 1.2em;
    }
    ${(props) =>
        props.inList
            ? `background-color: ${theme.colors.c1l1};
              color:white;`
            : `background-color: white;
              color:${theme.colors.c1};
              `}
`
