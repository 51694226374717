// *** slice : “a collection of Redux reducer logic and actions for a single feature in your app.”

// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import { resetSeriesSlice } from "store/seriesSlice"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

// ### STYLES ###################################################################

import { createSlice } from "@reduxjs/toolkit"

// Type for our state
interface AuthState {
    authState: boolean
    authUserToken: string | null
    authUsername: string | null
}

// Initial state
const initialState: AuthState = {
    authUsername: localStorage.getItem("username") || null,
    authUserToken: localStorage.getItem("userToken") || null,
    authState: localStorage.getItem("userToken") !== null, //  todo : renommer isConnected
}

// Actual Slice
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authLoginSuccess(state, action) {
            state.authState = true
            state.authUserToken = action.payload.userToken
            state.authUsername = action.payload.username
            localStorage.setItem("userToken", action.payload.userToken)
            localStorage.setItem("username", action.payload.username)
        },
        authLogoutSuccessX(state) {
            //

            state.authState = false
            state.authUserToken = null
            state.authUsername = null
            localStorage.removeItem("userToken")
            localStorage.removeItem("username")
        },
    },
})

export const { authLoginSuccess } = authSlice.actions

export default authSlice.reducer

const { authLogoutSuccessX } = authSlice.actions

export const authLogoutSuccess = () => (dispatch: any) => {
    console.log("'aaaaaaaaaaaaaa")
    dispatch(authLogoutSuccessX())
    dispatch(resetSeriesSlice())
}
