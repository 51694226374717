// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

// ### STYLES ###################################################################

import React from "react"
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
// import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export function FieldUsername({
    username,
    setUsername,
}: {
    username: string
    setUsername: any
}) {
    return (
        <FloatingLabel
            controlId="username"
            label="Nom d'utilisateur"
            className="mb-3 "
        >
            <Form.Control
                autoFocus
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            {username.length > 0 && username.length < 3 && (
                <small className="text-danger">
                    Le nom doit contenir au moins 3 caractères
                </small>
            )}
        </FloatingLabel>
    )
}

export function FieldEmail({
    email,
    setEmail,
}: {
    email: string
    setEmail: any
}) {
    return (
        <FloatingLabel controlId="email" label="Adresse eMail" className="mb-3">
            <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
        </FloatingLabel>
    )
}

export function FieldPassword({
    password,
    setPassword,
}: {
    password: string
    setPassword: any
}) {
    return (
        <FloatingLabel
            controlId="password"
            label="Mot de passe"
            className="mt-2"
        >
            <Form.Control
                type="password"
                value={password}
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
            />
            {password.length > 0 && password.length < 6 && (
                <small className="text-danger">
                    Le mot de passe doit contenir au moins 6 caractères
                </small>
            )}
        </FloatingLabel>
    )
}
export function FieldPassword2({
    password,
    password2,
    setPassword2,
}: {
    password: string
    password2: string
    setPassword2: any
}) {
    return (
        <FloatingLabel
            controlId="password2"
            label="Confirmez le mot de passe"
            className="mt-2"
        >
            <Form.Control
                type="password"
                value={password2}
                autoComplete="off"
                onChange={(e) => setPassword2(e.target.value)}
            />
            {password2.length > 0 && password2 !== password && (
                <p className="text-danger">
                    Les 2 mots de passe sont différent.
                </p>
            )}
        </FloatingLabel>
    )
}

/*
function Field({
    name,
    label,
    type,
    val,
    setVal,
}: {
    name: string
    label: string
    type: string
    val: string
    setVal: any
}) {
    return (
        <FloatingLabel controlId={name} label={label} className="mb-3">
            <Form.Control
                autoFocus
                type={type}
                value={val}
                onChange={(e) => setVal(e.target.value)}
                // placeholder="name@example.com"
            />
        </FloatingLabel>
    )
}
*/

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

// const StyledLoginForm = styled(Form)``
