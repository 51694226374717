/* eslint-disable react/prop-types */

// ██████████████████████████████████████████████████████████████████████████████
import React, { useState } from "react"
// import {useEffect}  from "react";
import { useAppDispatch } from "store/store"
import { addSerieUser } from "store/seriesSlice"
import styled from "styled-components"

import Button from "react-bootstrap/Button"
import { apiSetEpisodeVu, apiAddSerieToMyListAndSetEpisodeVu } from "api/apis"
// ██████████████████████████████████████████████████████████████████████████████

export default function ButtonEpisodeVu({
    //  serieId,
    seasonNumber,
    episodeNumber,
    serieUser,
    vu,
    tmdbSerieId,
}) {
    const [vuIsLoading, setVuIsLoading] = useState(false)
    const dispatch = useAppDispatch()

    if (vuIsLoading) {
        return <>...</>
    }

    return (
        <StyledButtonVu
            className={
                " btn-sm  " +
                (vu ? "btn-light btn-outline-warning" : "btn-warning")
            }
            onClick={() => handleEpisodeVuClick()}
        >
            {seasonNumber === 0 && episodeNumber === 0 ? "Aucun" : "Vu"}
        </StyledButtonVu>
    )

    function handleEpisodeVuClick() {
        // alert(serieId + " " + seasonNumber + "x" + episodeNumber)

        setVuIsLoading(true)
        if (serieUser) {
            apiSetEpisodeVu(serieUser.id, seasonNumber, episodeNumber).then(
                (result) => {
                    console.log("result:::::::", result)
                    /*
                if (result.serie) {
                    dispatch({ type: "CREATE_NOTE", payload: result.note })
                }
                */

                    window.location.reload()
                    setVuIsLoading(false)
                },
                (error) => {
                    setVuIsLoading(false)
                    //  TODO
                    console.log("error")
                    console.log(error)
                }
            )
        } else {
            // alert("TODO ; ADD serie + episode vu: #" + tmdbSerieId)
            apiAddSerieToMyListAndSetEpisodeVu(
                tmdbSerieId,
                seasonNumber,
                episodeNumber
            ).then(
                (result) => {
                    console.log("result:::::::", result)

                    if (result.serieUser) {
                        dispatch(addSerieUser(result.serieUser))
                    }

                    // window.location.reload()
                    setVuIsLoading(false)
                },
                (error) => {
                    setVuIsLoading(false)
                    //  TODO
                    console.log("error")
                    console.log(error)
                }
            )
        }
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledButtonVu = styled(Button)`
    font-size: 0.8em;
`
