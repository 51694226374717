// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageMesSeries() {
    return (
        <>
            <h2 className="text-primary text-center m-5">
                Commencez par ajouter des séries à votre watchList.
            </h2>
            <h5 className="text-primary text-center">
                Cliquez sur le menu "Rechercher"
            </h5>
        </>
    )
}

// ### STYLES ###################################################################
