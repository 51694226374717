/* eslint-disable no-nested-ternary */
//  ### DOCUMENTATION ############################################################

//  ### IMPORTS ##################################################################

import React from "react"
import { SerieUser, TmdbSerie } from "types"
import SaisonItem from "./SaisonItem"

//  ### TYPES ####################################################################

type Props = {
    serieUser: SerieUser | null
    tmdbSerie: TmdbSerie | null
}

//  ### FUNCTIONS ################################################################

export default function SeasonsAndEpisodes({ serieUser, tmdbSerie }: Props) {
    if (!tmdbSerie) {
        return <h1>!tmdbSerie</h1>
    }

    const saisons = []
    for (let i = tmdbSerie.number_of_seasons; i >= 0; i -= 1) {
        if (tmdbSerie["season/" + i]) {
            // console.log("saison "+i, serie['season/'+i])
            saisons.push(tmdbSerie["season/" + i])
        }
    }
    // console.log("tmdbApiGetSerieById.seasonsTemp", seasonsTemp)
    saisons.sort((a, b) =>
        a.season_number > b.season_number
            ? -1
            : b.season_number > a.season_number
            ? 1
            : 0
    )

    saisons.sort((a, b) =>
        a.number > b.number ? -1 : b.number > a.number ? 1 : 0
    )

    return (
        <div
            className=" col-12"
            // style={{ position: "absolute", top: 0, left: 0, zIndex: 999 }}
        >
            {/* 
            {serieUser && (
                <>
                    Dernier épisode vu: {serieUser.lastSeenSeasonNumber}x
                    {serieUser.lastSeenEpisodeNumber}
                </>
            )}
            */}
            {saisons?.map((saison) => (
                <SaisonItem
                    // eslint-disable-next-line dot-notation
                    key={"saison---" + saison["_id"]}
                    saison={saison}
                    // lastSeenEpisode={lastSeenEpisode}
                    serieUser={serieUser}
                    tmdbSerieId={tmdbSerie.id}
                />
            ))}
        </div>
    )
}
