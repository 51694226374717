// ### IMPORTS ##################################################################

import React from "react"
import styled from "styled-components"
import { ZErrorInterface } from "libs/zError"

// ### COMPONENT ################################################################

export default function FormRegisterZError({
    zError,
}: {
    zError: ZErrorInterface
}) {
    if (zError.detail === "ERR_EMAIL_EXISTING") {
        return <StyledError>Cette adresse email existe déjà.</StyledError>
    }
    if (zError.detail === "ERR_USERNAME_EXISTING") {
        return <StyledError>Ce nom d'utilisateur existe déjà.</StyledError>
    }

    return <StyledError>{zError.detail}</StyledError>
}

// ### STYLES ###################################################################

const StyledError = styled.div`
    color: red;
    margin-top: 10px;
`
