// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"
// import SeriesListe from "features/series/_seriesIndex/SeriesListe"
import SeriesTable from "features/SeriesTable"
import { Serie } from "types"
import { apiFetchSeries } from "api/apis"
import { ZPage, ZPageHeader, ZPageContent } from "libs/zPage"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageAdminSeriesIndex() {
    const [series, setSeries] = useState<Serie[] | null>(null)

    console.log(series)

    return (
        <ZPage
            documentTitle="Series"
            fetchFunction={() => apiFetchSeries()}
            fetchSuccessFunction={(result: any) => setSeries(result.series)}
        >
            <ZPageHeader>
                <h1>Admin: Series</h1>
            </ZPageHeader>
            <ZPageContent>
                {series && <SeriesTable series={series} />}
                {/*   {series && <SeriesListe series={series} />}   */}
            </ZPageContent>
        </ZPage>
    )
}

// ### STYLES ###################################################################
