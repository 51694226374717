// ### IMPORTS ##################################################################

import React from "react"
import FormLogin from "features/auth/FormLogin"
import { ButtonNavLinkRegister } from "features/auth/common/buttonsNavLink"

// ### COMPONENT ################################################################

export default function PageAuthLogin() {
    return (
        <div className="row col-12">
            <div className="mt-5 m-auto" style={{ width: "300px" }}>
                <h1>WL</h1>
                <FormLogin />
                <br />
                <br />
                <ButtonNavLinkRegister />
            </div>
        </div>
    )
}

// ### STYLES ###################################################################
