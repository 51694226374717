// ### IMPORTS ##################################################################

import React from "react"
import Button from "react-bootstrap/Button"

// ### TYPES ####################################################################

type Props = {
    url: string
    text: string
    variant?: string
}

ButtonLien.defaultProps = {
    variant: "primary",
}

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function ButtonLien({ url, text, variant }: Props) {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
            <Button className={`btn-light btn-outline-${variant} m-1`}>
                {clearUrl(text)}
            </Button>
        </a>
    )

    function clearUrl(urlx: string) {
        let url2 = urlx.replace(/http(s)?:\/\//, "")
        url2 = url2.replace(/www./, "")
        url2 = url2.replace(/\/(.)*/, "")
        return url2
    }
}

/*
const StyledButtonLien = styled.a`

`;
*/
