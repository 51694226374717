/* eslint-disable no-nested-ternary */
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"
// import styled from "styled-components"
// import Link from "next/link"
import { Serie, TmdbSerie /* , SerieUser */ } from "types"
// import ButtonUpdateFromApis from "features/ButtonUpdateFromApis"
// import SerieUserStatus from "features/SerieUserStatus"
import SerieLiens from "./SerieLiens"
// import TruncatedText from "../TruncatedText"

// ### TYPES ####################################################################

type Props = {
    serie: Serie | null
    tmdbSerie: TmdbSerie | null
    // serieUser: SerieUser | null
}

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function SerieInfos({
    serie,
    tmdbSerie /* , serieUser */,
}: Props) {
    const combinedSerie = {
        status: tmdbSerie?.status || serie?.status,

        lastEpisodeSeasonNumber:
            tmdbSerie?.last_episode_to_air?.season_number ||
            serie?.tmdbLastEpisodeSeasonNumber,

        lastEpisodeNumber:
            tmdbSerie?.last_episode_to_air?.episode_number ||
            serie?.tmdbLastEpisodeNumber,

        lastEpisodeDate:
            tmdbSerie?.last_episode_to_air?.air_date ||
            serie?.tmdbLastEpisodeDate,

        nextEpisodeSeasonNumber:
            tmdbSerie?.next_episode_to_air?.season_number ||
            serie?.tmdbNextEpisodeSeasonNumber,

        nextEpisodeNumber:
            tmdbSerie?.next_episode_to_air?.episode_number ||
            serie?.tmdbNextEpisodeNumber,

        nextEpisodeDate:
            tmdbSerie?.next_episode_to_air?.air_date ||
            serie?.tmdbNextEpisodeDate,
    }

    return (
        <div className="row col-12">
            <div className="col-12 pb-3">
                Série en production :{" "}
                <b>
                    {serie?.tmdbInProduction === null
                        ? "?"
                        : serie?.tmdbInProduction
                        ? "Oui"
                        : "Non"}
                </b>{" "}
                ({combinedSerie.status})
            </div>
            {/* 
            <div className="col-12 pb-3">
                <b>{serie?.tmdbNumberOfSeasons}</b> saison
                {serie?.tmdbNumberOfSeasons &&
                    serie?.tmdbNumberOfSeasons > 1 &&
                    "s"}
            </div>
            */}

            <div className="col-12 pb-3">
                Dernier épisode:{" "}
                <b>
                    {combinedSerie.lastEpisodeSeasonNumber}x
                    {combinedSerie.lastEpisodeNumber}
                </b>{" "}
                ({combinedSerie?.lastEpisodeDate?.replace(" 00:00:00", "")})
            </div>

            <div className="col-12 pb-3">
                Prochain épisode:{" "}
                <b>
                    {combinedSerie?.nextEpisodeSeasonNumber}x
                    {combinedSerie?.nextEpisodeNumber}
                </b>{" "}
                ({combinedSerie?.nextEpisodeDate?.replace(" 00:00:00", "")})
            </div>

            <h4>Synopsis:</h4>
            <div className="row col-12 pb-3">
                {tmdbSerie?.overview || serie?.tmdbOverview}
            </div>
            {/*
            <div className="col-12 pb-3">
                Dernier Check: <b>{serie?.tmdbLastCheckAt}</b>
            </div>
            <div className="col-12 pb-3">
                Dernière mise à jour: <b>{serie?.tmdbLastUpdateAt}</b>
                {serie?.tmdbId && <ButtonUpdateFromApis serie={serie} />}
            </div>
             */}
            {/*
            {serie?.tmdbPosterPath && (
                <img
                    // style={{ width: "100%" }}
                    className="col-12"
                    src={
                        "https://image.tmdb.org/t/p/w780/" +
                        serie?.tmdbBackdropPath
                    }
                    alt="tmdbBackdropPath"
                />
            )}
             */}

            <div className="col-12">
                <h4>Liens:</h4>
                <SerieLiens serie={serie} tmdbSerie={tmdbSerie} />
            </div>
        </div>
    )
}

// ### STYLES ###################################################################

/*
const StyledPageHeader = styled.div`
    color: blue;
`
*/
